import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { DOTS } from "../lib/usePagination";
import { Pagination as PaginationModel } from "../models/ui-pagination";

interface PaginationProps {
  pagination: PaginationModel;
  count?: {
    total: number;
    start: number;
    end: number;
  };
}

const Pagination: React.FC<PaginationProps> = ({ count, pagination }) => {
  const { t } = useTranslation();

  if (pagination.range.length < 2) {
    return null;
  }

  const onNext = () => {
    pagination.next();
  };

  const onPrevious = () => {
    pagination.previous();
  };

  let lastPage = pagination.range[pagination.range.length - 1];

  return (
    <div className="flex items-center justify-between">
      {/* Mobile */}
      <div className="flex-1 flex justify-between lg:hidden">
        <button
          disabled={pagination.active === 1}
          onClick={onPrevious}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:hover:bg-white disabled:border-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed"
        >
          {t("previous")}
        </button>

        <button
          disabled={pagination.active === lastPage}
          onClick={onNext}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:hover:bg-white disabled:border-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed"
        >
          {t("next")}
        </button>
      </div>

      {/* Desktop */}
      <div className="hidden lg:flex-1 lg:flex lg:items-center lg:justify-between">
        {/* Count */}
        {!!count && (
          <div>
            <p className="text-sm text-gray-700">
              <Trans
                t={t}
                i18nKey="pagination-count"
                values={count}
                components={{
                  strong: <span className="font-medium" />,
                }}
              />
            </p>
          </div>
        )}

        {/* Pages */}
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              disabled={pagination.active === 1}
              onClick={onPrevious}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:hover:bg-white disabled:border-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed"
            >
              <span className="sr-only">{t("previous")}</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {/* Pages */}
            {pagination.range.map((pageNumber, index) => {
              // Dots
              if (pageNumber === DOTS) {
                return (
                  <span
                    key={index}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                  >
                    &hellip;
                  </span>
                );
              }

              // Page
              return (
                <button
                  key={index}
                  onClick={() => pagination.setPage(pageNumber)}
                  className={classNames(
                    "relative inline-flex items-center px-4 py-2 border text-sm font-medium",
                    {
                      "z-10 bg-blue-50 border-andritz-500 text-andritz-600":
                        pageNumber === pagination.active,
                      "bg-white border-gray-300 text-gray-500 hover:bg-gray-50":
                        pageNumber !== pagination.active,
                    }
                  )}
                >
                  {pageNumber}
                </button>
              );
            })}

            <button
              disabled={pagination.active === lastPage}
              onClick={onNext}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:hover:bg-white disabled:border-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed"
            >
              <span className="sr-only">{t("next")}</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
