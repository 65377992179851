import { FilterIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import React, { useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import FilterClothsTable from "../components/filter-cloths-table";
import Header from "../components/header";
import TableActions from "../components/table-actions";
import TableSearch from "../components/table-search";
import { useSearch } from "../lib/useSearch";
import { useSort } from "../lib/useSort";
import { useUiFilters } from "../lib/useUiFilters";
import { FilterCloth } from "../models/filter-cloth";
import { useGetAllFilterCloths } from "../operations/queries/filter-cloths";
import { useGetAllFilterPresses } from "../operations/queries/filter-presses";

const FilterClothsPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  // Get filter cloths from API
  const { loading, error, filterCloths } = useGetAllFilterCloths();

  // Filter presses for filtering
  const { filterPresses } = useGetAllFilterPresses();

  // Set up UI filters
  const {
    filteredObjects: filteredFilterCloths,
    filterState,
    filterGroups,
    filterChangeHandler,
  } = useUiFilters<FilterCloth>(
    filterCloths,
    useCallback(() => {
      // Sort filter presses alphabetically
      const sortedFilterPresses = !!filterPresses?.length
        ? [...filterPresses].sort((a, b) =>
            a.title.localeCompare(b.title, i18n.resolvedLanguage, {
              numeric: true,
            })
          )
        : filterPresses;

      // Return filters
      return sortedFilterPresses?.length
        ? [
            {
              id: "status",
              name: t("status"),
              filters: [
                { value: "installed", label: t("installed") },
                { value: "not-installed", label: t("not-installed") },
              ],
            },
            {
              id: "press",
              name: t("filter-press"),
              filters: sortedFilterPresses.map((filterPress) => ({
                itemGroup: filterPress.location?.name,
                value: filterPress.id,
                label: filterPress.title,
              })),
            },
          ]
        : [];
    }, [filterPresses, t, i18n]),
    (filterCloth, filterStateItemKey) => ({
      status:
        (filterStateItemKey === "installed" && filterCloth.installed) ||
        (filterStateItemKey === "not-installed" && !filterCloth.installed),
      press: filterCloth?.plate?.press?.id === filterStateItemKey,
    })
  );

  // Search
  const {
    searchedObjects: searchedFilterCloths,
    searchTerm,
    searchChangeHandler,
  } = useSearch<FilterCloth>(filteredFilterCloths, (filterCloth) =>
    compact([
      filterCloth.fcid,
      filterCloth.plate?.title,
      filterCloth.plate?.press.title,
    ])
  );

  // Sort
  const { sortBy, setSortBy, compareFn, sortOptions } = useSort<FilterCloth>(
    {
      "installed-at": (a, b) => {
        const aInstalledTime = !!a?.installedTime
          ? new Date(a?.installedTime)
          : undefined;
        const bInstalledTime = !!b?.installedTime
          ? new Date(b?.installedTime)
          : undefined;

        if (!aInstalledTime) return 1;
        if (!bInstalledTime) return -1;
        return bInstalledTime.getTime() - aInstalledTime.getTime();
      },
      fcid: (a, b) => a.fcid.localeCompare(b.fcid),
      status: (a, b) =>
        a.installed === b.installed ? 0 : a.installed ? -1 : 1,
      "filter-press": (a, b) => {
        // No filter press => item should be in the back
        if (!a.plate?.press) return 1;
        if (!b.plate?.press) return -1;
        // Press present, sort alphabetically
        return a.plate.press.title.localeCompare(
          b.plate.press.title,
          i18n.resolvedLanguage,
          { numeric: true }
        );
      },
    },
    [
      {
        key: "installed-at",
        label: t("installed-at"),
      },
      {
        key: "fcid",
        label: t("id"),
      },
      {
        key: "status",
        label: t("status"),
      },
      {
        key: "filter-press",
        label: t("filter-press"),
      },
    ]
  );

  return (
    <>
      <Helmet>
        <title>{t("filter-cloth", { count: 2 })} | Smart Filtercloth</title>
      </Helmet>

      <Header title={t("filter-cloth", { count: 2 })} Icon={FilterIcon} />

      <div className="px-4 sm:px-6 md:px-8 py-6">
        <div className="max-w-screen-2xl">
          {/* Actions & list */}
          <div className="shadow rounded-md bg-white px-4 py-5 sm:px-6 space-y-4">
            <TableSearch
              searchChangeHandler={searchChangeHandler}
              searchTerm={searchTerm}
            />
            <TableActions
              filterChangeHandler={filterChangeHandler}
              filterGroups={filterGroups}
              filterState={filterState}
              sortState={{ setSortBy, sortBy, sortOptions }}
            />
            <FilterClothsTable
              filterCloths={searchedFilterCloths}
              compareFn={compareFn}
              loading={loading}
              error={error}
              sortState={{ setSortBy, sortBy, sortOptions }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterClothsPage;
