import React from "react";

interface TrProps {
  bgGray: boolean;
}

const Tr: React.FC<TrProps> = ({ children, bgGray = false }) => {
  return <tr className={bgGray ? "bg-gray-50" : "bg-white"}>{children}</tr>;
};

export default Tr;
