import React from "react";
import { Link, LinkProps } from "react-router-dom";

const LinkComponent: React.FC<LinkProps> = ({ children, ...props }) => {
  // const router = useRouter();
  // const locale = rest.locale || router.query.locale || "";

  // let href = rest.href || router.asPath;
  // if (href.indexOf("http") === 0) {
  //   skipLocaleHandling = true;
  // }
  // if (locale && !skipLocaleHandling) {
  //   href = href
  //     ? `/${locale}${href}`
  //     : router.pathname.replace("[locale]", locale);
  // }

  return <Link {...props}>{children}</Link>;
};

export default LinkComponent;
