import { ClockIcon, UserIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Alert from "../components/alert";
import CardTooltip from "../components/card-tooltip";
import CircularProgress from "../components/circular-progress";
import { CurrentTime } from "../components/current-time";
import FilterPlateHeatmap from "../components/filter-plate-heatmap";
import FilterPlateStatus from "../components/filter-plate-status";
import FilterPressSelect from "../components/filter-press-select";
import FilterPressTransactions from "../components/filter-press-transactions";
import Header from "../components/header";
import Link from "../components/link";
import OperationTimesStat from "../components/stats/operation-times";
import RecentInstallsStat from "../components/stats/recent-installs";
import UpcomingReplacementsStat from "../components/stats/upcoming-replacements";
import { useLoadable } from "../lib/useLoadable";
import { FilterPress } from "../models/filter-press";
import {
  useGetAllFilterPresses,
  useGetFilterPress,
} from "../operations/queries/filter-presses";
import { selectedCustomerState } from "../state";

const DashboardPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  // Get selected customer
  const {
    error: selectedCustomerError,
    loading: selectedCustomerLoading,
    result: selectedCustomer,
  } = useLoadable(selectedCustomerState);

  // Get filter presses from API
  const {
    loading: filterPressesLoading,
    error: filterPressesError,
    filterPresses,
  } = useGetAllFilterPresses();

  // Sort filter presses alphabetically
  const [sortedFilterPresses, setSortedFilterPresses] = useState<
    FilterPress[] | undefined
  >();

  useEffect(() => {
    setSortedFilterPresses(
      !!filterPresses?.length
        ? [...filterPresses].sort((a, b) =>
            a.title.localeCompare(b.title, i18n.resolvedLanguage, {
              numeric: true,
            })
          )
        : filterPresses
    );
  }, [filterPresses, i18n]);

  // Filter press selection state (working on non-detailed filter presses from useGetAllFilterPresses)
  const [selectedFilterPress, setSelectedFilterPress] = useState<FilterPress>();

  // Set initially active filter press
  useEffect(() => {
    !!sortedFilterPresses?.length &&
      setSelectedFilterPress(sortedFilterPresses[0]);
  }, [sortedFilterPresses]);

  // Load detailed selected filter press
  const {
    loading: detailedFilterPressLoading,
    error: detailedFilterPressError,
    filterPress: detailedSelectedFilterPress,
  } = useGetFilterPress(selectedFilterPress?.id);

  const error =
    selectedCustomerError || filterPressesError || detailedFilterPressError;
  const loading =
    selectedCustomerLoading ||
    filterPressesLoading ||
    detailedFilterPressLoading;

  return (
    <>
      <Helmet>
        <title>{t("dashboard")} | Smart Filtercloth</title>
      </Helmet>

      <Header
        title={t("dashboard")}
        meta={
          <>
            {/* Selected customer */}
            {!!selectedCustomer && (
              <Link
                to={`/customers/detail?id=${selectedCustomer.id}`}
                className={
                  "mt-2 flex items-center text-sm text-gray-500 hover:text-gray-900"
                }
              >
                <UserIcon
                  className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {selectedCustomer.name}
              </Link>
            )}

            {/* Current time */}
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <ClockIcon
                className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <CurrentTime />
            </div>
          </>
        }
      />

      <div className="px-4 sm:px-6 md:px-8 py-6">
        <div className="space-y-6 max-w-screen-2xl">
          {/* Error alert */}
          {!!error && (
            <div className="shadow rounded-md bg-white overflow-hidden px-4 py-5 sm:px-6">
              <Alert severity="error">
                <p>{error.message}</p>
              </Alert>
            </div>
          )}

          {/* Content */}
          {loading ? (
            <CircularProgress />
          ) : !!detailedSelectedFilterPress && !!sortedFilterPresses?.length ? (
            <>
              <div className="sm:max-w-full sm:min-w-[min(28rem,100%)] sm:w-fit">
                <FilterPressSelect
                  filterPresses={sortedFilterPresses}
                  selectedFilterPress={detailedSelectedFilterPress}
                  setSelectedFilterPress={setSelectedFilterPress}
                />
              </div>

              {/* Top grid */}
              <div className="grid xl:grid-cols-2 3xl:grid-cols-5 gap-6 items-start">
                {/* KPIs */}
                <div className="3xl:col-span-3 grid gap-6 items-start">
                  <div className="grid 3xl:grid-cols-2 gap-6 items-start sm:max-w-md xl:max-w-none xl:order-1">
                    <RecentInstallsStat press={detailedSelectedFilterPress} />
                    <UpcomingReplacementsStat
                      press={detailedSelectedFilterPress}
                    />
                  </div>

                  <OperationTimesStat press={detailedSelectedFilterPress} />
                </div>

                {/* Latest transactions */}
                <div className="3xl:col-span-2 shadow rounded-md bg-white overflow-hidden">
                  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {t("latest-transactions")}
                    </h3>
                  </div>
                  <div className="px-4 py-5 sm:px-6">
                    <FilterPressTransactions
                      pressId={detailedSelectedFilterPress.id}
                      limit={3}
                    />
                  </div>
                </div>
              </div>

              {/* Plate status */}
              <div className="shadow rounded-md bg-white overflow-hidden">
                <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                  <div className="flex space-x-3">
                    <div className="flex-shrink-0 flex-1">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {t("filter-plate-status")}
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {t("filter-plate-status-description")}
                      </p>
                    </div>

                    <div className="flex-shrink-0 self-center flex">
                      <CardTooltip>
                        <p className="text-sm text-gray-500">
                          {t("filter-plate-status-info")}
                        </p>
                      </CardTooltip>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-5 sm:px-6">
                  <FilterPlateStatus press={detailedSelectedFilterPress} />
                </div>
              </div>

              {/* Plate heatmap */}
              <div className="shadow rounded-md bg-white overflow-hidden">
                <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {t("filter-plate-heatmap")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {t("filter-plate-heatmap-description")}
                  </p>
                </div>
                <div className="px-4 py-5 sm:px-6">
                  <FilterPlateHeatmap press={detailedSelectedFilterPress} />
                </div>
              </div>
            </>
          ) : (
            <div className="shadow rounded-md bg-white overflow-hidden px-4 py-5 sm:px-6">
              <Alert severity="info">
                <p>{t("filter-presses-zero")}</p>
              </Alert>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
