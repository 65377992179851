import { GlobeAltIcon, UserIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React, { createContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Alert from "../../components/alert";
import CircularProgress from "../../components/circular-progress";
import FilterPlateTransactions from "../../components/filter-plate-transactions";
import Header from "../../components/header";
import Heatmap from "../../components/heatmap";
import FilterPressIconSolid from "../../components/icons/FilterPressIconSolid";
import Link from "../../components/link";
import { findClothMode } from "../../lib/helpers";
import useEscape from "../../lib/useEscape";
import { Area } from "../../models/heatmap";
import { useGetFilterPlate } from "../../operations/queries/filter-plates";
import Custom404 from "../404";

export const SelectedAreaContext = createContext<Area | undefined>(undefined);

const FilterPlatePage: React.FC = () => {
  const { t } = useTranslation();

  // Selected area UI state
  const [selectedArea, setSelectedArea] = useState<Area>();
  const toggleSelectedArea = (area: Area) => {
    setSelectedArea(area === selectedArea ? undefined : area);
  };
  useEscape(() => setSelectedArea(undefined));

  // Get filter plate id from URL params
  const [searchParams] = useSearchParams();
  const filterPlateId = searchParams.get("id") ?? undefined;

  // Fetch filter plate data
  const { loading, error, filterPlate } = useGetFilterPlate(filterPlateId);

  if (!loading && !filterPlate && !error) {
    return <Custom404 />;
  }

  const clothMode = filterPlate?.plateIndex
    ? findClothMode(
        filterPlate?.press.clothModeConfig || null,
        filterPlate.plateIndex
      )
    : null;

  // Info & specification content
  const infoContent = [
    {
      head: t("plate-index"),
      content: filterPlate?.plateIndex,
    },
    {
      head: t("cloth-mode"),
      content: clothMode ? t(clothMode) : undefined,
    },
    {
      head: t("filter-cloth-single"),
      content: filterPlate?.cloth?.id ? (
        <Link
          to={`/filter-cloths/detail?id=${filterPlate?.cloth?.id}`}
          className="font-medium text-andritz-500 hover:text-andritz-600"
        >
          {t("view")}
        </Link>
      ) : (
        t(
          !!filterPlate?.clothFront?.id || !!filterPlate?.clothBack?.id
            ? "not-applicable"
            : "no-filter-cloth-installed"
        )
      ),
    },
    {
      head: t("filter-cloth-front"),
      content: !!filterPlate?.clothFront?.id ? (
        <Link
          to={`/filter-cloths/detail?id=${filterPlate?.clothFront?.id}`}
          className="font-medium text-andritz-500 hover:text-andritz-600"
        >
          {t("view")}
        </Link>
      ) : (
        t(
          !!filterPlate?.cloth?.id
            ? "not-applicable"
            : "no-filter-cloth-installed"
        )
      ),
    },
    {
      head: t("filter-cloth-back"),
      content: !!filterPlate?.clothBack?.id ? (
        <Link
          to={`/filter-cloths/detail?id=${filterPlate?.clothBack?.id}`}
          className="font-medium text-andritz-500 hover:text-andritz-600"
        >
          {t("view")}
        </Link>
      ) : (
        t(
          !!filterPlate?.cloth?.id
            ? "not-applicable"
            : "no-filter-cloth-installed"
        )
      ),
    },
  ].filter((row) => !!row.content);

  return (
    <SelectedAreaContext.Provider value={selectedArea}>
      <Helmet>
        {!!filterPlate?.title && (
          <title>{filterPlate.title} | Smart Filtercloth</title>
        )}
      </Helmet>

      <Header
        title={loading ? <CircularProgress /> : filterPlate?.title}
        meta={
          loading || !filterPlate ? undefined : (
            <>
              {/* Filter press link */}
              {!filterPlate?.press ? undefined : (
                <Link
                  to={`/filter-presses/detail?id=${filterPlate.press.id}`}
                  className={
                    "mt-2 flex items-center text-sm text-gray-500 hover:text-gray-900"
                  }
                >
                  <FilterPressIconSolid
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {filterPlate.press.title}
                </Link>
              )}

              {/* Location link */}
              {!filterPlate?.press.location ? undefined : (
                <Link
                  to={`/locations/detail?id=${filterPlate?.press.location.id}`}
                  className={
                    "mt-2 flex items-center text-sm text-gray-500 hover:text-gray-900"
                  }
                >
                  <GlobeAltIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {filterPlate?.press.location.name}
                </Link>
              )}

              {/* Customer link */}
              {!filterPlate?.press.location?.customer ? undefined : (
                <Link
                  to={`/customers/detail?id=${filterPlate?.press.location.customer.id}`}
                  className={
                    "mt-2 flex items-center text-sm text-gray-500 hover:text-gray-900"
                  }
                >
                  <UserIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {filterPlate?.press.location?.customer.name}
                </Link>
              )}
            </>
          )
        }
        breadcrumb={
          !!filterPlate
            ? [
                {
                  title: t("filter-plate", { count: 2 }),
                  href: "/filter-plates",
                },
                {
                  title: filterPlate?.title,
                  href: `/filter-plates/detail?id=${filterPlate.id}`,
                },
              ]
            : []
        }
      />

      {/* Content */}
      <div className="px-4 sm:px-6 md:px-8 py-6">
        {/* Error alert */}
        {!!error && (
          <div className="max-w-screen-2xl">
            <div className="shadow rounded-md bg-white overflow-hidden px-4 py-5 sm:px-6">
              <Alert severity="error">
                <p>{error.message}</p>
              </Alert>
            </div>
          </div>
        )}

        {/* Content */}
        {!!filterPlate && (
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-6 max-w-screen-2xl">
            {/* Info & specifications */}
            <div className="shadow rounded-md bg-white overflow-hidden xl:col-span-2">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {t("info-specifications")}
                </h3>
              </div>

              <div className="border-t border-gray-200">
                <dl>
                  {infoContent.map(({ head, content }, index) => (
                    <div
                      key={head}
                      className={classNames(
                        "px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      )}
                    >
                      <dt className="text-sm font-medium text-gray-500">
                        {head}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {content}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>

            {/* Heatmap */}
            <div className="shadow rounded-md bg-white overflow-hidden">
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {t("heatmap")}
                </h3>
              </div>
              <div className="px-4 py-5 sm:px-6">
                <div className="max-w-3xl">
                  <Heatmap
                    toggleSelectedArea={toggleSelectedArea}
                    heatmapAreas={filterPlate?.heatmapAreas}
                  />
                </div>
              </div>
            </div>

            {/* Transactions */}
            <div className="shadow rounded-md bg-white overflow-hidden">
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {t("transaction", { count: 2 })}
                </h3>
              </div>
              <div className="px-4 py-5 sm:px-6 space-y-4">
                <FilterPlateTransactions plateId={filterPlate.id} />
              </div>
            </div>
          </div>
        )}
      </div>
    </SelectedAreaContext.Provider>
  );
};

export default FilterPlatePage;
