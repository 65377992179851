import { gql } from "@apollo/client";
import { FilterCloth } from "./filter-cloth";
import { FilterPlate } from "./filter-plate";
import { Area } from "./heatmap";
import { User, fragments as userFragments } from "./user";

export interface Transaction {
  id: string;
  createdAt: string;
  type: "install" | "replace" | "uninstall";
  plate: FilterPlate;
  side: "single" | "front" | "back";
  oldCloth?: FilterCloth;
  newCloth?: FilterCloth;
  user?: User;
  reason?: Reason;
  comment?: string;
  highlightedAreas?: Area[];
  photo?: {
    id: string;
    url: string;
  };
  cycleNumber?: number;
  completedCycles?: number;
}

export type ServerTransaction = Omit<Transaction, "highlightedAreas"> & {
  highlightedAreas?: string;
};

export type TransactionContext = "press" | "plate" | "cloth";
export type Reason =
  | "damaged"
  | "clogging"
  | "scheduled"
  | "cleaning"
  | "other";

export const fragments = {
  transaction: gql`
    fragment Transaction on Transaction {
      id
      createdAt
      type
      user {
        ...User
      }
      reason
      comment
      highlightedAreas
      photo {
        id
        url
      }
      cycleNumber
      completedCycles
    }
    ${userFragments.user}
  `,
};
