import React from "react";
import { FilterCloth } from "../models/filter-cloth";
import { useGetTransactions } from "../operations/queries/transactions";
import Alert from "./alert";
import CircularProgress from "./circular-progress";
import Transactions from "./transactions";

interface FilterClothTransactionsProps {
  cloth: FilterCloth;
}

const FilterClothTransactions: React.FC<FilterClothTransactionsProps> = ({
  cloth,
}) => {
  // Load all transactions that affected the current cloth
  const { loading, error, transactions } = useGetTransactions({
    OR: [
      {
        oldCloth: {
          id: {
            equals: cloth.id,
          },
        },
      },
      {
        newCloth: {
          id: {
            equals: cloth.id,
          },
        },
      },
    ],
  });

  return (
    <div className="space-y-4">
      {/* Error alert */}
      {!!error && (
        <Alert severity="error">
          <p>{error.message}</p>
        </Alert>
      )}

      {/* Transactions */}
      {loading ? (
        <CircularProgress />
      ) : !!transactions ? (
        <Transactions
          transactions={transactions}
          context="cloth"
          cloth={cloth}
        />
      ) : null}
    </div>
  );
};

export default FilterClothTransactions;
