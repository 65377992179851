import { gql } from "@apollo/client";
import { FilterPlate } from "./filter-plate";
import { Area } from "./heatmap";
import { Reason } from "./transaction";

export interface FilterCloth {
  id: string;
  fcid: string;
  modelNumber: string;
  installedTime: string;
  uninstalledTime: string;
  plate?: FilterPlate;
  installed: boolean;
  uninstallReason?: Reason;
  highlightedAreas?: Area[];
}

export type ServerFilterCloth = Omit<FilterCloth, "highlightedAreas"> & {
  highlightedAreas?: string;
};

export const fragments = {
  filterCloth: gql`
    fragment FilterCloth on FilterCloth {
      id
      fcid
      modelNumber
      installed
      installedTime
      uninstalledTime
      uninstallReason
      highlightedAreas
    }
  `,
};
