import { atom, selector } from "recoil";
import client from "./apollo-client";
import { Customer } from "./models/customer";
import { GET_ALL_CUSTOMERS_QUERY } from "./operations/queries/customers";
import { localStorageEffect } from "./stateEffects";

export const initiallySelectedCustomerState = selector({
  key: "initiallySelectedCustomerState",
  get: async () => {
    const { data } = await client.query<{
      customers: Customer[];
    }>({
      query: GET_ALL_CUSTOMERS_QUERY,
    });

    // Initial customer = first fetched customer (sorted alphabetically)
    return data?.customers?.length ? data.customers[0] : null;
  },
});

export const selectedCustomerState = atom<Customer | null>({
  key: "selectedCustomerState",
  default: initiallySelectedCustomerState,
  effects_UNSTABLE: [
    localStorageEffect<Customer | null>("@selectedCustomerState", null),
  ],
});
