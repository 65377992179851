import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import React from "react";
import Link from "./link";

export interface BreadcrumbItem {
  title: string;
  href: string;
}

interface BreadcrumbProps {
  breadcrumb: BreadcrumbItem[];
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumb }) => {
  if (!breadcrumb.length) {
    return null;
  }

  return (
    <div>
      {/* Mobile */}
      {breadcrumb.length >= 1 && (
        <nav className="sm:hidden" aria-label="Back">
          <Link
            to={breadcrumb[0].href}
            className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
          >
            <ChevronLeftIcon
              className="shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Back
          </Link>
        </nav>
      )}

      {/* Tablet up */}
      <nav className="hidden sm:flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2">
          {breadcrumb.length >= 1 &&
            breadcrumb.map((item, i) => {
              if (!item || item.title.length === 0) {
                return null;
              }

              return (
                <li key={item.href}>
                  <div className="flex items-center">
                    {i > 0 && (
                      <ChevronRightIcon
                        className="shrink-0 h-5 w-5 text-gray-400 mr-2"
                        aria-hidden="true"
                      />
                    )}
                    <Link
                      to={item.href}
                      className="text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      {item.title}
                    </Link>
                  </div>
                </li>
              );
            })}
        </ol>
      </nav>
    </div>
  );
};
