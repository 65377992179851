import { ApolloError } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import Link from "../components/link";
import { smartTrim } from "../lib/helpers";
import { FilterCloth } from "../models/filter-cloth";
import { SortState } from "../models/ui-sort";
import StatusBadge from "./status-badge";
import Table from "./table-elements/table";
import Td from "./table-elements/td";
import Th from "./table-elements/th";
import ThSortButton from "./table-elements/th-sort-button";
import Tr from "./table-elements/tr";
import TrError from "./table-elements/tr-error";
import TrLoading from "./table-elements/tr-loading";
import TrZero from "./table-elements/tr-zero";

interface FilterClothsTableProps {
  loading: boolean;
  error: ApolloError | undefined;
  filterCloths: FilterCloth[] | undefined;
  compareFn: (a: FilterCloth, b: FilterCloth) => number;
  sortState?: SortState;
}

const FilterClothsTable: React.FC<FilterClothsTableProps> = ({
  loading,
  error,
  filterCloths,
  compareFn,
  sortState,
}) => {
  const { t } = useTranslation();

  return (
    <Table>
      <thead className="bg-gray-50">
        <tr>
          <Th>
            <ThSortButton
              sortState={sortState}
              sortKey="fcid"
              label={t("id")}
            />
          </Th>
          <Th>
            <ThSortButton
              sortState={sortState}
              sortKey="status"
              label={t("status")}
            />
          </Th>
          <Th>{t("filter-plate")}</Th>
          <Th>
            <ThSortButton
              sortState={sortState}
              sortKey="filter-press"
              label={t("filter-press")}
            />
          </Th>
          <Th last={true}>
            <span className="sr-only">{t("view")}</span>
          </Th>
        </tr>
      </thead>

      {/* Render filter cloth content or info/error state */}
      <tbody>
        {loading ? (
          // Loading state
          <TrLoading />
        ) : error ? (
          // Error state
          <TrError>
            <p>{error.toString()}</p>
          </TrError>
        ) : filterCloths?.length ? (
          // Filter cloth list
          filterCloths.sort(compareFn).map((filterCloth, idx) => (
            <Tr key={filterCloth.id} bgGray={idx % 2 === 1}>
              <Td>
                <Link
                  to={`/filter-cloths/detail?id=${filterCloth.id}`}
                  className="text-andritz-500 hover:text-andritz-600 font-mono"
                >
                  {smartTrim(filterCloth.fcid)}
                </Link>
              </Td>
              <Td>
                <StatusBadge status={filterCloth.installed} type="installed" />
              </Td>
              <Td>
                {!!filterCloth.plate && (
                  <Link
                    to={`/filter-plates/detail?id=${filterCloth.plate.id}`}
                    className="text-andritz-500 hover:text-andritz-600"
                  >
                    {filterCloth.plate.title}
                  </Link>
                )}
              </Td>
              <Td>
                {!!filterCloth.plate?.press && (
                  <Link
                    to={`/filter-presses/detail?id=${filterCloth.plate?.press.id}`}
                    className="text-andritz-500 hover:text-andritz-600"
                  >
                    {filterCloth.plate?.press.title}
                  </Link>
                )}
              </Td>
              <Td last={true}>
                <Link
                  to={`/filter-cloths/detail?id=${filterCloth.id}`}
                  className="font-medium text-andritz-500 hover:text-andritz-600"
                >
                  {t("view")}
                </Link>
              </Td>
            </Tr>
          ))
        ) : (
          // Zero state
          <TrZero>{t("filter-cloths-zero")}</TrZero>
        )}
      </tbody>
    </Table>
  );
};

export default FilterClothsTable;
