import { Listbox, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CogIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import { differenceInDays, formatDistanceStrict, subMonths } from "date-fns";
import { round } from "lodash";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { days2Ms, getDateFnsLocale } from "../../lib/helpers";
import { FilterPress } from "../../models/filter-press";
import CardTooltip from "../card-tooltip";

interface OperationTimesStatProps {
  press: FilterPress;
}

const OperationTimesStat: React.FC<OperationTimesStatProps> = ({ press }) => {
  const { t, i18n } = useTranslation();
  const locale = getDateFnsLocale(i18n.resolvedLanguage);
  const maxOperationTime = days2Ms(press.maxPlateOperationTime);

  const now = Date.now();

  const timeframeOptions: {
    name: string;
    value: "month" | "threeMonths";
    days: number;
  }[] = [
    {
      name: t("last-month"),
      value: "month",
      days: differenceInDays(subMonths(now, 1), now),
    },
    {
      name: t("last-three-months"),
      value: "threeMonths",
      days: differenceInDays(subMonths(now, 3), now),
    },
  ];

  const [timeframe, setTimeframe] = useState(timeframeOptions[0]);

  const stats = !!press?.operationTimes
    ? [
        {
          name: t("stat-operation-time-min"),
          statString: press.operationTimes[timeframe.value].min,
        },
        {
          name: t("stat-operation-time-max"),
          statString: press.operationTimes[timeframe.value].max,
        },
        {
          name: t("stat-operation-time-avg"),
          statString: press.operationTimes[timeframe.value].average,
        },
      ].map((stat) => {
        const rawStat = isFinite(+stat.statString) ? +stat.statString : null;

        return {
          ...stat,
          formattedStat:
            rawStat === null
              ? "-"
              : formatDistanceStrict(rawStat, 0, {
                  unit: "day",
                  roundingMethod: "ceil",
                  locale,
                }),
          deviation:
            rawStat === null
              ? null
              : `${Math.abs(
                  round((rawStat / maxOperationTime) * 100 - 100, 2)
                )}%`,
          deviationType:
            rawStat === null
              ? null
              : rawStat > maxOperationTime
              ? "above"
              : "below",
        };
      })
    : [];

  return (
    <div className="shadow rounded-md bg-white overflow-hidden">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="flex gap-x-3 justify-between items-center">
          <div className="flex-shrink-0 flex-1">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t("stat-operation-time-title")}
            </h3>
          </div>

          <div className="flex-shrink-0 flex gap-2 items-center sm:-mr-3 h-0 overflow-visible">
            <Listbox
              as="div"
              value={timeframe}
              onChange={setTimeframe}
              className="flex-shrink-0"
            >
              {({ open }) => (
                <>
                  <Listbox.Label className="sr-only">
                    {t("select-timeframe")}
                  </Listbox.Label>
                  <div className="relative">
                    <Listbox.Button className="relative inline-flex items-center rounded-full p-2 bg-gray-100 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-200 sm:py-1.5 sm:px-3">
                      <CogIcon
                        className="flex-shrink-0 h-5 w-5 sm:-ml-1"
                        aria-hidden="true"
                      />

                      <span className="hidden truncate sm:ml-2 sm:block text-gray-900">
                        {timeframe.name}
                      </span>
                    </Listbox.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-75"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Listbox.Options className="origin-top-right absolute right-0 top-full z-10 mt-1 w-48 bg-white shadow-lg rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {timeframeOptions.map((timeframeOption) => (
                          <Listbox.Option
                            key={timeframeOption.value}
                            className={({ active }) =>
                              classNames(
                                active ? "bg-gray-100" : "bg-white",
                                "cursor-pointer select-none relative py-2 px-3"
                              )
                            }
                            value={timeframeOption}
                          >
                            <div className="flex items-center">
                              <span className="block font-medium truncate">
                                {timeframeOption.name}
                              </span>
                            </div>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>

            <CardTooltip>
              <p className="text-sm text-gray-500">
                {t("stat-operation-time-info")}
              </p>
            </CardTooltip>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-1 2xl:grid-cols-3 gap-6">
          {stats.map(({ name, formattedStat, deviation, deviationType }) => {
            return (
              <div key={name}>
                {/* Title */}
                <dt className="text-sm font-medium text-gray-500 truncate">
                  {name}
                </dt>

                <dd className="flex flex-wrap items-baseline gap-x-2 gap-y-0.5">
                  {/* Stat */}
                  <p className="text-2xl font-semibold text-gray-900 leading-tight">
                    {formattedStat}
                  </p>

                  {/* Deviation */}
                  {deviation !== null && (
                    <p
                      className={classNames(
                        deviationType === "above"
                          ? "text-red-600"
                          : "text-green-600",
                        "flex items-baseline text-sm font-semibold"
                      )}
                    >
                      {deviationType === "above" ? (
                        <ChevronUpIcon
                          className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronDownIcon
                          className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                      )}

                      <span className="sr-only">
                        {t("deviation-by")}{" "}
                        {deviationType === "above" ? "+" : "-"}
                      </span>

                      {deviation}
                    </p>
                  )}
                </dd>
              </div>
            );
          })}
        </dl>
      </div>
    </div>
  );
};

export default OperationTimesStat;
