import { de, enUS, es, pt } from "date-fns/locale";
import { FilterPress } from "../models/filter-press";
import { Area } from "../models/heatmap";

export const getDateFnsLocale = (locale: string): Locale | undefined => {
  return {
    en: enUS,
    de: de,
    es: es,
    pt: pt,
  }[locale];
};

export const smartTrim = (string: string, maxLength: number = 12) => {
  if (!string) return string;
  if (maxLength < 1) return string;
  if (string.length <= maxLength) return string;
  if (maxLength === 1) return string.substring(0, 1) + "…";

  var midpoint = Math.ceil(string.length / 2);
  var toremove = string.length - maxLength;
  var lstrip = Math.ceil(toremove / 2);
  var rstrip = toremove - lstrip;
  return (
    string.substring(0, midpoint - lstrip) +
    "…" +
    string.substring(midpoint + rstrip)
  );
};

export const areas: Area[] = [
  "A1",
  "B1",
  "C1",
  "A2",
  "B2",
  "C2",
  "A3",
  "B3",
  "C3",
];

export const parseHighlightedAreas = (highlightedAreas: string): Area[] =>
  highlightedAreas
    .split(",")
    .filter((v) => v)
    .sort() as Area[];

export const int2roman = (original: number): string => {
  if (original < 1 || original > 3999) {
    return String(original);
  }

  const numerals = [
    ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"], // 1-9
    ["X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC"], // 10-90
    ["C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM"], // 100-900
    ["M", "MM", "MMM"], // 1000-3000
  ];

  const digits = Math.round(original).toString().split("");
  let position = digits.length - 1;

  return digits.reduce((roman, digit) => {
    if (digit !== "0") {
      roman += numerals[position][parseInt(digit) - 1];
    }

    position -= 1;

    return roman;
  }, "");
};

export const days2Ms = (days: number) => days * 24 * 60 * 60 * 1000;

export const findClothMode = (
  clothModeConfig: FilterPress["clothModeConfig"],
  plateIndex: number
) => {
  if (!clothModeConfig) {
    return null;
  }

  return clothModeConfig.find((entry) => entry.plateIndex === plateIndex)
    ?.clothMode;
};
