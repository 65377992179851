import { GlobeAltIcon } from "@heroicons/react/outline";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Header from "../components/header";
import LocationsTable from "../components/locations-table";
import { useGetAllLocations } from "../operations/queries/locations";

const LocationsPage: React.FC = () => {
  const { t } = useTranslation();

  // Get locations from API
  const { loading, error, locations } = useGetAllLocations();

  return (
    <>
      <Helmet>
        <title>{t("location", { count: 2 })} | Smart Filtercloth</title>
      </Helmet>

      <Header title={t("location", { count: 2 })} Icon={GlobeAltIcon} />

      <div className="px-4 sm:px-6 md:px-8 py-6">
        <div className="max-w-screen-2xl">
          {/* Actions & list */}
          <div className="shadow rounded-md bg-white px-4 py-5 sm:px-6 space-y-4">
            <LocationsTable
              loading={loading}
              error={error}
              locations={locations}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationsPage;
