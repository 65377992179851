import { LocationMarkerIcon } from "@heroicons/react/solid";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import CircularProgress from "../../components/circular-progress";
import Header from "../../components/header";
import LocationsTable from "../../components/locations-table";
import { useGetCustomer } from "../../operations/queries/customers";
import Custom404 from "../404";

const CustomerPage: React.FC = () => {
  const { t } = useTranslation();

  // Get customer id from URL params
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("id") ?? undefined;

  // Fetch customer data
  const { loading, error, customer } = useGetCustomer(customerId);

  if (!loading && !customer) {
    return <Custom404 />;
  }

  return (
    <>
      <Helmet>
        {!!customer?.name && <title>{customer.name} | Smart Filtercloth</title>}
      </Helmet>

      <Header
        title={loading ? <CircularProgress /> : customer?.name}
        meta={
          loading || !customer?.address ? undefined : (
            <div className={"mt-2 flex items-center text-sm text-gray-500"}>
              <LocationMarkerIcon
                className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {customer?.address}
            </div>
          )
        }
        breadcrumb={
          !!customer
            ? [
                {
                  title: t("customer", { count: 2 }),
                  href: "/customers",
                },
                {
                  title: customer?.name,
                  href: `/customers/detail?id=${customer.id}`,
                },
              ]
            : []
        }
      />

      <div className="px-4 sm:px-6 md:px-8 py-6">
        <div className="max-w-screen-2xl">
          <div className="shadow rounded-md bg-white px-4 py-5 sm:px-6 space-y-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t("location", { count: 2 })}
            </h3>

            {/* Locations */}
            <LocationsTable
              loading={loading}
              error={error}
              locations={customer?.locations}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerPage;
