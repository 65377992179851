import { gql, useQuery } from "@apollo/client";
import { parseHighlightedAreas } from "../../lib/helpers";
import { fragments as filterClothFragments } from "../../models/filter-cloth";
import { fragments as filterPlateFragments } from "../../models/filter-plate";
import {
  fragments as transactionFragments,
  ServerTransaction,
  Transaction,
} from "../../models/transaction";

export const GET_TRANSACTIONS_QUERY = gql`
  query TRANSACTIONS_QUERY($filter: TransactionWhereInput, $limit: Int) {
    transactions(orderBy: { createdAt: desc }, where: $filter, take: $limit) {
      ...Transaction
      plate {
        ...FilterPlate
      }
      side
      newCloth {
        ...FilterCloth
      }
      oldCloth {
        ...FilterCloth
      }
    }
  }
  ${transactionFragments.transaction}
  ${filterPlateFragments.filterPlate}
  ${filterClothFragments.filterCloth}
`;

export const useGetTransactions = (filter: any, limit?: number) => {
  const { loading, error, data } = useQuery<{
    transactions: ServerTransaction[];
  }>(GET_TRANSACTIONS_QUERY, {
    variables: {
      filter,
      limit,
    },
  });

  const transactions: Transaction[] | undefined = data?.transactions
    ? data.transactions.map((transaction) => ({
        ...transaction,
        highlightedAreas: transaction.highlightedAreas
          ? parseHighlightedAreas(transaction.highlightedAreas)
          : undefined,
      }))
    : undefined;

  return {
    loading,
    error,
    transactions,
  };
};
