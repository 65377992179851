import { gql } from "@apollo/client";

export interface User {
  email: string;
  name: string;
  id: string;
  isAdmin: boolean;
}

export const fragments = {
  user: gql`
    fragment User on User {
      id
      name
    }
  `,
};
