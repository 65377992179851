import { LocationMarkerIcon, UserIcon } from "@heroicons/react/solid";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import CircularProgress from "../../components/circular-progress";
import FilterPressesTable from "../../components/filter-presses-table";
import Header from "../../components/header";
import Link from "../../components/link";
import { useGetLocation } from "../../operations/queries/locations";
import Custom404 from "../404";

const LocationPage: React.FC = () => {
  const { t } = useTranslation();

  // Get location id from URL params
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get("id") ?? undefined;

  // Fetch location data
  const { loading, error, location } = useGetLocation(locationId);

  if (!loading && !location) {
    return <Custom404 />;
  }

  return (
    <>
      <Helmet>
        {!!location?.name && <title>{location.name} | Smart Filtercloth</title>}
      </Helmet>

      <Header
        title={loading ? <CircularProgress /> : location?.name}
        meta={
          loading || !location ? undefined : (
            <>
              {/* Address */}
              {!location?.address ? undefined : (
                <div className={"mt-2 flex items-center text-sm text-gray-500"}>
                  <LocationMarkerIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {location?.address}
                </div>
              )}

              {/* Customer link */}
              {!location?.customer ? undefined : (
                <Link
                  to={`/customers/detail?id=${location.customer.id}`}
                  className={
                    "mt-2 flex items-center text-sm text-gray-500 hover:text-gray-900"
                  }
                >
                  <UserIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {location?.customer.name}
                </Link>
              )}
            </>
          )
        }
        breadcrumb={
          !!location
            ? [
                {
                  title: t("location", { count: 2 }),
                  href: "/locations",
                },
                {
                  title: location?.name,
                  href: `/locations/detail?id=${location.id}`,
                },
              ]
            : []
        }
      />

      <div className="px-4 sm:px-6 md:px-8 py-6">
        <div className="max-w-screen-2xl">
          <div className="shadow rounded-md bg-white px-4 py-5 sm:px-6 space-y-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t("filter-press", { count: 2 })}
            </h3>

            {/* Presses */}
            <FilterPressesTable
              loading={loading}
              error={error}
              filterPresses={location?.presses}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationPage;
