import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { useLoadable } from "../lib/useLoadable";
import { useGetAllCustomers } from "../operations/queries/customers";
import { selectedCustomerState } from "../state";
import Link from "./link";
import Table from "./table-elements/table";
import Td from "./table-elements/td";
import Th from "./table-elements/th";
import Tr from "./table-elements/tr";
import TrError from "./table-elements/tr-error";
import TrLoading from "./table-elements/tr-loading";
import TrZero from "./table-elements/tr-zero";

interface CustomersTableProps {}

const CustomersTable: React.FC<CustomersTableProps> = () => {
  const { t } = useTranslation();

  // Get customers from API
  const {
    loading: allCustomersLoading,
    error: allCustomersError,
    customers,
  } = useGetAllCustomers();

  // Get selected customer
  const {
    error: selectedCustomerError,
    loading: selectedCustomerLoading,
    result: selectedCustomer,
  } = useLoadable(selectedCustomerState);
  const setSelectedCustomer = useSetRecoilState(selectedCustomerState);

  const error = selectedCustomerError || allCustomersError;
  const loading = selectedCustomerLoading || allCustomersLoading;

  return (
    <Table>
      <thead className="bg-gray-50">
        <tr>
          <Th>{t("name")}</Th>
          <Th>{t("address")}</Th>
          <Th>{t("selected")}</Th>
          <Th last={true}>
            <span className="sr-only">{t("view")}</span>
          </Th>
        </tr>
      </thead>

      {/* Render content or info/error state */}
      <tbody>
        {loading ? (
          // Loading state
          <TrLoading />
        ) : error ? (
          // Error state
          <TrError>
            <p>{error.toString()}</p>
          </TrError>
        ) : customers?.length ? (
          // Table
          customers.map((customer, idx) => {
            const selectCustomerKey = `select-customer-${customer.id}`;
            const selected = selectedCustomer?.id === customer.id;

            return (
              <Tr key={customer.id} bgGray={idx % 2 === 1}>
                <Td>
                  <Link
                    to={`/customers/detail?id=${customer.id}`}
                    className="text-andritz-500 hover:text-andritz-600"
                  >
                    {customer.name}
                  </Link>
                </Td>
                <Td>{customer.address}</Td>
                <Td>
                  <label
                    key={selectCustomerKey}
                    className={classNames("flex items-center gap-3 group", {
                      "cursor-pointer": !selected,
                    })}
                  >
                    <input
                      id={selectCustomerKey}
                      name="selected-customer"
                      type="radio"
                      checked={selected}
                      className="focus:ring-andritz-500 h-4 w-4 text-andritz-500 border-gray-300 [cursor:inherit]"
                      onChange={() => {
                        setSelectedCustomer(customer);
                      }}
                      disabled={selected}
                    />
                    <span
                      className={classNames("text-sm", {
                        "block font-medium text-andritz-500 group-hover:text-andritz-600":
                          !selected,
                        "inline-flex items-center px-2 rounded-full font-medium bg-green-100 text-green-800":
                          selected,
                      })}
                    >
                      {t(selected ? "selected" : "select")}
                    </span>
                  </label>
                </Td>
                <Td last={true}>
                  <Link
                    to={`/customers/detail?id=${customer.id}`}
                    className="font-medium text-andritz-500 hover:text-andritz-600"
                  >
                    {t("view")}
                  </Link>
                </Td>
              </Tr>
            );
          })
        ) : (
          // Zero state
          <TrZero>{t("customers-zero")}</TrZero>
        )}
      </tbody>
    </Table>
  );
};

export default CustomersTable;
