import { ApolloError } from "@apollo/client";
import { RecoilValue, useRecoilValueLoadable } from "recoil";

// Custom hook to get an asynchronous recoil value from state
export function useLoadable<T>(recoilValue: RecoilValue<T>) {
  let error: ApolloError | undefined;
  let loading = false;
  let result: T | null = null;

  const loadable = useRecoilValueLoadable(recoilValue);

  if (loadable.state === "hasValue" && !!loadable.contents) {
    result = loadable.contents;

    // Loadable is loading
  } else if (loadable.state === "loading") {
    loading = true;

    // Loadable has error
  } else if (loadable.state === "hasError") {
    error = loadable.contents;
  }

  return { error, loading, result, loadable };
}
