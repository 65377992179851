/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "./circular-progress";

interface LightboxProps {
  url: string;
}

const Lightbox: React.FunctionComponent<LightboxProps> = ({ url }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [image, setImage] = useState<HTMLImageElement | null>(null);

  const [imageStatus, setImageStatus] = useState<
    "loading" | "loaded" | "error"
  >("loading");

  // Reset loading state on URL change
  useEffect(() => {
    !!image?.complete ? setImageStatus("loaded") : setImageStatus("loading");
  }, [url, image]);

  // Close modal on error
  useEffect(() => {
    if (imageStatus === "error") {
      setOpen(false);
    }
  }, [imageStatus]);

  return (
    <>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="font-medium text-andritz-500 hover:text-andritz-600"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        {t("view")}
      </a>

      <Transition.Root
        show={open}
        as={Fragment}
        afterLeave={() => {
          setImageStatus("loading");
        }}
      >
        <Dialog as="div" className="fixed z-10 inset-0" onClose={setOpen}>
          <div className="flex justify-center items-center h-full px-4 text-center py-8">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity flex items-center justify-center">
                {imageStatus === "loading" && (
                  <CircularProgress dimensions="h-10 w-10" color="text-white" />
                )}
              </Dialog.Overlay>
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="rounded-lg overflow-hidden shadow-xl transform transition max-h-[min(100%,960px)] max-w-[min(100%,1024px)]"
                style={{
                  aspectRatio: !!image
                    ? `${image.naturalWidth} / ${image.naturalHeight}`
                    : undefined,
                }}
              >
                <img
                  ref={(image) => {
                    setImage(image);
                  }}
                  src={url}
                  alt=""
                  className={classNames("w-full h-full", {
                    "sr-only": imageStatus === "loading",
                  })}
                  onLoad={() => {
                    window.requestAnimationFrame(() => {
                      setImageStatus("loaded");
                    });
                  }}
                  onError={() => {
                    setImageStatus("error");
                  }}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Lightbox;
