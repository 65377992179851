const FilterPlateIconSolid: React.FC<React.ComponentProps<"svg">> = ({
  ...props
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V15C3 15.5304 3.21071 16.0391 3.58579 16.4142C3.96086 16.7893 4.46957 17 5 17H15C15.5304 17 16.0391 16.7893 16.4142 16.4142C16.7893 16.0391 17 15.5304 17 15V5C17 4.46957 16.7893 3.96086 16.4142 3.58579C16.0391 3.21071 15.5304 3 15 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579ZM5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H6.01C6.27522 5 6.52957 5.10536 6.71711 5.29289C6.90464 5.48043 7.01 5.73478 7.01 6C7.01 6.26522 6.90464 6.51957 6.71711 6.70711C6.52957 6.89464 6.27522 7 6.01 7H6C5.73478 7 5.48043 6.89464 5.29289 6.70711C5.10536 6.51957 5 6.26522 5 6C5 5.73478 5.10536 5.48043 5.29289 5.29289ZM13.2929 5.29289C13.4804 5.10536 13.7348 5 14 5C14.2652 5 14.5196 5.10536 14.7071 5.29289C14.8946 5.48043 15 5.73478 15 6C15 6.26522 14.8946 6.51957 14.7071 6.70711C14.5196 6.89464 14.2652 7 14 7C13.7348 7 13.4804 6.89464 13.2929 6.70711C13.1054 6.51957 13 6.26522 13 6C13 5.73478 13.1054 5.48043 13.2929 5.29289ZM5.29289 13.2929C5.48043 13.1054 5.73478 13 6 13H6.01C6.27522 13 6.52957 13.1054 6.71711 13.2929C6.90464 13.4804 7.01 13.7348 7.01 14C7.01 14.2652 6.90464 14.5196 6.71711 14.7071C6.52957 14.8946 6.27522 15 6.01 15H6C5.73478 15 5.48043 14.8946 5.29289 14.7071C5.10536 14.5196 5 14.2652 5 14C5 13.7348 5.10536 13.4804 5.29289 13.2929ZM13.2929 13.2929C13.4804 13.1054 13.7348 13 14 13C14.2652 13 14.5196 13.1054 14.7071 13.2929C14.8946 13.4804 15 13.7348 15 14C15 14.2652 14.8946 14.5196 14.7071 14.7071C14.5196 14.8946 14.2652 15 14 15C13.7348 15 13.4804 14.8946 13.2929 14.7071C13.1054 14.5196 13 14.2652 13 14C13 13.7348 13.1054 13.4804 13.2929 13.2929ZM9.99005 8C9.46226 8 8.95608 8.21072 8.58287 8.58579C8.20967 8.96086 8 9.46957 8 10C8 10.5304 8.20967 11.0391 8.58287 11.4142C8.95608 11.7893 9.46226 12 9.99005 12H10.01C10.5377 12 11.0439 11.7893 11.4171 11.4142C11.7903 11.0391 12 10.5304 12 10C12 9.46957 11.7903 8.96086 11.4171 8.58579C11.0439 8.21072 10.5377 8 10.01 8H9.99005Z"
        fill="currentColor"
      />
      <path
        d="M19.292 9.056L17 6V10H18.82C19.3062 10 19.5837 9.44495 19.292 9.056Z"
        fill="currentColor"
      />
      <path
        d="M3 6L0.707999 9.056C0.416287 9.44495 0.693813 10 1.18 10H3V6Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FilterPlateIconSolid;
