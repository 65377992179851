import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface StatusBadgeProps {
  status: boolean;
  type: "active" | "installed";
}
const labels = {
  active: {
    true: "active",
    false: "not-active",
  },
  installed: {
    true: "installed",
    false: "not-installed",
  },
};

const StatusBadge: React.FC<StatusBadgeProps> = ({ status, type }) => {
  const { t } = useTranslation();

  return (
    <span
      className={classNames(
        "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium",
        status ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
      )}
    >
      {t(labels[type][status ? "true" : "false"])}
    </span>
  );
};

export default StatusBadge;
