import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDateFnsLocale } from "../lib/helpers";

export const CurrentTime = () => {
  const { i18n } = useTranslation();
  const locale = getDateFnsLocale(i18n.resolvedLanguage);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  });

  return <>{format(time, "PPPP, pp", { locale })}</>;
};
