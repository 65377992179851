const FilterPlateIcon: React.FC<React.ComponentProps<"svg">> = ({
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 4C4.9 4 4 4.9 4 6V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V6C20 4.9 19.1 4 18 4H6Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 8L1 10L3 10L3 8Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 10L23 10L21 8L21 10Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14V10ZM12.01 14C13.1146 14 14.01 13.1046 14.01 12C14.01 10.8954 13.1146 10 12.01 10V14ZM12 14H12.01V10H12V14Z"
        fill="currentColor"
      />
      <path
        d="M17 16C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18V16ZM17.01 18C17.5623 18 18.01 17.5523 18.01 17C18.01 16.4477 17.5623 16 17.01 16V18ZM17 18H17.01V16H17V18Z"
        fill="currentColor"
      />
      <path
        d="M17 6C16.4477 6 16 6.44772 16 7C16 7.55228 16.4477 8 17 8V6ZM17.01 8C17.5623 8 18.01 7.55228 18.01 7C18.01 6.44772 17.5623 6 17.01 6V8ZM17 8H17.01V6H17V8Z"
        fill="currentColor"
      />
      <path
        d="M7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18V16ZM7.01 18C7.56228 18 8.01 17.5523 8.01 17C8.01 16.4477 7.56228 16 7.01 16V18ZM7 18H7.01V16H7V18Z"
        fill="currentColor"
      />
      <path
        d="M7 6C6.44772 6 6 6.44772 6 7C6 7.55228 6.44772 8 7 8V6ZM7.01 8C7.56228 8 8.01 7.55228 8.01 7C8.01 6.44772 7.56228 6 7.01 6V8ZM7 8H7.01V6H7V8Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FilterPlateIcon;
