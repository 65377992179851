import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ChartBarIcon,
  GlobeAltIcon,
  MenuIcon,
  UserGroupIcon,
  XIcon,
} from "@heroicons/react/outline";
import { CogIcon, LogoutIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import FilterClothIcon from "../components/icons/FilterClothIcon";
import FilterPlateIcon from "../components/icons/FilterPlateIcon";
import FilterPressIcon from "../components/icons/FilterPressIcon";
import Link from "../components/link";
import { useSignOut } from "../operations/mutations/user";
import { useUser } from "../operations/queries/user";

const makeInitials = (name: string): string => {
  let regexp = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  let initialsMatches = [...name.matchAll(regexp)] || [];

  return (
    (initialsMatches.shift()?.[1] || "") + (initialsMatches.pop()?.[1] || "")
  ).toUpperCase();
};

const Avatar: React.FC<{ initials: string; size: "sm" | "base" }> = ({
  initials,
  size,
}) => (
  <span
    className={classnames(
      {
        "h-8 w-8": size === "sm",
        "h-9 w-9": size === "base",
      },
      "inline-flex items-center justify-center rounded-full bg-andritz-500 group-focus:ring-2 group-focus:ring-offset-2 group-focus:ring-andritz-500"
    )}
  >
    <span
      className={classnames(
        {
          "text-sm": size === "sm",
          "text-base": size === "base",
        },
        "font-medium leading-none text-white"
      )}
    >
      {initials}
    </span>
  </span>
);

const MenuDropup: React.FC<{ size: "sm" | "base" }> = ({ size }) => {
  const { t } = useTranslation();
  const [signOut] = useSignOut();
  const { user } = useUser();

  const items = [
    {
      name: "account-settings",
      href: "/account",
      icon: CogIcon,
      attributes: {},
    },
    {
      name: "sign-out",
      href: "#",
      icon: LogoutIcon,
      attributes: {
        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          signOut();
        },
      },
    },
  ];

  // User should always be defined since <SignInGate> wraps layout
  if (!user) return null;

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="shrink-0 w-full group block focus:outline-none">
          <div className="flex items-center">
            <div>
              <Avatar initials={makeInitials(user.name)} size={size} />
            </div>
            <div className="ml-3">
              <p
                className={classnames(
                  {
                    "text-sm": (size = "sm"),
                    "text-base": (size = "base"),
                  },
                  "font-medium text-gray-700 group-hover:text-gray-900"
                )}
              >
                {user.name}
              </p>
            </div>
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items className="origin-bottom-left absolute left-0 bottom-full mb-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="px-4 py-3">
            <p className="text-sm">{t("signed-in-as")}</p>
            <p className="text-sm font-medium text-gray-900 truncate">
              {user.email}
            </p>
          </div>
          <div className="py-1">
            {items.map((item) => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <Link
                    to={item.href}
                    className={classnames(
                      {
                        "bg-gray-100 text-gray-900": active,
                        "text-gray-700": !active,
                      },
                      "group flex items-center py-2 px-4 text-sm"
                    )}
                    {...item.attributes}
                  >
                    <item.icon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    {t(item.name)}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

interface LayoutProps {}

const Layout: React.FC<LayoutProps> = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const router = useRouter();
  const { t } = useTranslation();

  // Close sidebar on route change
  let location = useLocation();
  useEffect(() => {
    setSidebarOpen(false);
  }, [location]);

  const navigation = [
    {
      name: t("dashboard"),
      href: "/",
      icon: ChartBarIcon,
    },
    {
      name: t("customer", { count: 2 }),
      href: "/customers",
      icon: UserGroupIcon,
    },
    {
      name: t("location", { count: 2 }),
      href: "/locations",
      icon: GlobeAltIcon,
    },
    {
      name: t("filter-press", { count: 2 }),
      href: "/filter-presses",
      icon: FilterPressIcon,
    },
    {
      name: t("filter-plate", { count: 2 }),
      href: "/filter-plates",
      icon: FilterPlateIcon,
    },
    {
      name: t("filter-cloth", { count: 2 }),
      href: "/filter-cloths",
      icon: FilterClothIcon,
    },
  ];

  return (
    <div>
      {/* Mobile sidebar */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">{t("close-sidebar")}</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="shrink-0 flex items-center px-4 h-8">
                  <Link to="/" className="h-6 inline-block">
                    <img
                      className="h-full w-auto"
                      src="/images/andritz-logo.svg"
                      alt="Andritz"
                    />
                  </Link>
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {navigation.map((item) => (
                    <NavLink
                      to={item.href}
                      key={item.name}
                      className={({ isActive }) =>
                        classnames(
                          {
                            "bg-gray-100 text-gray-900": isActive,
                            "text-gray-600 hover:bg-gray-50 hover:text-gray-900":
                              !isActive,
                          },
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )
                      }
                    >
                      {({ isActive }) => (
                        <>
                          <item.icon
                            className={classnames(
                              {
                                "text-gray-500": isActive,
                                "text-gray-400 group-hover:text-gray-500":
                                  !isActive,
                              },
                              "mr-4 shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </>
                      )}
                    </NavLink>
                  ))}
                </nav>
              </div>
              <div className="shrink-0 flex border-t border-gray-200 p-4">
                <MenuDropup size="base" />
              </div>
            </div>
          </Transition.Child>
          <div className="shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Desktop sidebar */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center shrink-0 px-4 h-8">
              <Link to="/" className="h-6 inline-block">
                <img
                  className="h-full w-auto"
                  src="/images/andritz-logo.svg"
                  alt="Andritz"
                />
              </Link>
            </div>
            <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={({ isActive }) =>
                    classnames(
                      {
                        "bg-gray-100 text-gray-900": isActive,
                        "text-gray-600 hover:bg-gray-50 hover:text-gray-900":
                          !isActive,
                      },
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )
                  }
                >
                  {({ isActive }) => (
                    <>
                      <item.icon
                        className={classnames(
                          {
                            "text-gray-500": isActive,
                            "text-gray-400 group-hover:text-gray-500":
                              !isActive,
                          },
                          "mr-3 shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </>
                  )}
                </NavLink>
              ))}
            </nav>
          </div>
          <div className="shrink-0 flex border-t border-gray-200 p-4">
            <MenuDropup size="sm" />
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="md:pl-64 flex flex-col flex-1">
        {/* Mobile topbar */}
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 bg-white border-b border-gray-200">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-andritz-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">{t("open-sidebar")}</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <main className="flex-1">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
