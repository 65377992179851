import { compact } from "lodash";
import React, { useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import FilterPlatesTable from "../components/filter-plates-table";
import Header from "../components/header";
import TableActions from "../components/table-actions";
import TableSearch from "../components/table-search";
import { useSearch } from "../lib/useSearch";
import { useUiFilters } from "../lib/useUiFilters";
import { FilterPlate } from "../models/filter-plate";
import { useGetAllFilterPlates } from "../operations/queries/filter-plates";
import { useGetAllFilterPresses } from "../operations/queries/filter-presses";

const FilterPlatesPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  // Get filter plates from API
  const { loading, error, filterPlates } = useGetAllFilterPlates();

  // Filter presses for filtering
  const { filterPresses } = useGetAllFilterPresses();

  // Set up UI filters
  const {
    filteredObjects: filteredFilterPlates,
    filterState,
    filterGroups,
    filterChangeHandler,
  } = useUiFilters<FilterPlate>(
    filterPlates,
    useCallback(() => {
      // Sort filter presses alphabetically
      const sortedFilterPresses = !!filterPresses?.length
        ? [...filterPresses].sort((a, b) =>
            a.title.localeCompare(b.title, i18n.resolvedLanguage, {
              numeric: true,
            })
          )
        : filterPresses;

      // Return filters
      return sortedFilterPresses?.length
        ? [
            {
              id: "cloth-installed",
              name: t("cloth-installed"),
              filters: [
                { value: "installed", label: t("installed") },
                { value: "not-installed", label: t("not-installed") },
              ],
            },
            {
              id: "press",
              name: t("filter-press"),
              filters: sortedFilterPresses.map((filterPress) => ({
                itemGroup: filterPress.location?.name,
                value: filterPress.id,
                label: filterPress.title,
              })),
            },
          ]
        : [];
    }, [filterPresses, t, i18n]),
    (filterPlate, filterStateItemKey) => ({
      "cloth-installed":
        (filterStateItemKey === "installed" && filterPlate.clothInstalled) ||
        (filterStateItemKey === "not-installed" && !filterPlate.clothInstalled),
      press: filterPlate?.press?.id === filterStateItemKey,
    })
  );

  // Search
  const {
    searchedObjects: searchedFilterPlates,
    searchTerm,
    searchChangeHandler,
  } = useSearch<FilterPlate>(filteredFilterPlates, (filterPlate) =>
    compact([
      filterPlate.plateIndex + "",
      filterPlate.title,
      filterPlate.press.title,
    ])
  );

  return (
    <>
      <Helmet>
        <title>{t("filter-plate", { count: 2 })} | Smart Filtercloth</title>
      </Helmet>

      <Header title={t("filter-plate", { count: 2 })} />

      <div className="px-4 sm:px-6 md:px-8 py-6">
        <div className="max-w-screen-2xl">
          {/* Actions & list */}
          <div className="shadow rounded-md bg-white px-4 py-5 sm:px-6 space-y-4">
            <TableSearch
              searchChangeHandler={searchChangeHandler}
              searchTerm={searchTerm}
            />
            <TableActions
              filterChangeHandler={filterChangeHandler}
              filterGroups={filterGroups}
              filterState={filterState}
            />
            <FilterPlatesTable
              loading={loading}
              error={error}
              filterPlates={searchedFilterPlates}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterPlatesPage;
