const FilterPressIconSolid: React.FC<React.ComponentProps<"svg">> = ({
  ...props
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 5C1 3.89543 1.89543 3 3 3V3V16C3 16.5523 2.55228 17 2 17V17C1.44772 17 1 16.5523 1 16V5Z"
        fill="currentColor"
      />
      <rect x="4" y="6" width="2" height="9" rx="1" fill="currentColor" />
      <rect x="7" y="6" width="2" height="9" rx="1" fill="currentColor" />
      <rect x="10" y="6" width="2" height="9" rx="1" fill="currentColor" />
      <rect x="14" y="6" width="2" height="9" rx="1" fill="currentColor" />
      <path
        d="M17 3V3C18.1046 3 19 3.89543 19 5V16C19 16.5523 18.5523 17 18 17V17C17.4477 17 17 16.5523 17 16V3Z"
        fill="currentColor"
      />
      <path
        d="M1 5C1 3.89543 1.89543 3 3 3H17C18.1046 3 19 3.89543 19 5V5H1V5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FilterPressIconSolid;
