import Gradient from "javascript-color-gradient";
import { round } from "lodash";
import React from "react";
import colors from "tailwindcss/colors";
import { int2roman } from "../lib/helpers";
import { FilterPress } from "../models/filter-press";

interface PlateHeatmapProps {
  press: FilterPress;
}

const FilterPlateHeatmap: React.FC<PlateHeatmapProps> = ({ press }) => {
  const numberOfPlates = press.numberOfPlates;
  const frameHeight = 67.73;
  const frameWidth = 152.4;
  const barWidth = 8.47;
  const platesHeight = frameHeight - barWidth * 2;

  if (!press.sectors) {
    return null;
  }

  // Set up percentage colors
  const percentageColors = new Gradient();
  percentageColors.setGradient(
    colors.emerald[300],
    colors.amber[300],
    colors.red[300]
  );

  return (
    <div className="relative">
      {/* Background SVG */}
      <svg
        viewBox="0 0 152.4 67.73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="block w-full uppercase rounded-md"
      >
        <path
          d="M0 0v8.467h152.4V0H0Zm0 59.267v8.466h152.4v-8.466H0Z"
          className="fill-gray-200"
        />
        <path d="M0 0v67.733h8.467V0H0Z" className="fill-gray-200" />
        <text
          transform="rotate(-90 22.337 23.663)"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontWeight={500}
          fontSize={9.094}
          letterSpacing="0em"
          className="fill-white"
        >
          <tspan x={0.22} y={8.808}>
            Head
          </tspan>
        </text>
        <text
          transform="rotate(-90 92.804 -49.804)"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontWeight={500}
          fontSize={9.122}
          letterSpacing="0em"
          className="fill-gray-200"
        >
          <tspan x={0.28} y={8.808}>
            Tail
          </tspan>
        </text>
      </svg>

      {/* Sectors */}
      <div
        className="absolute flex justify-between"
        style={{
          left: `${(barWidth / frameWidth) * 100}%`,
          right: `${(barWidth / frameWidth) * 100}%`,
          top: `${(barWidth / frameHeight) * 100}%`,
          bottom: `${(barWidth / frameHeight) * 100}%`,
        }}
      >
        {press.sectors.map(({ index, plates, percentage }) => (
          // Sector
          <div
            key={index}
            className="relative flex justify-between"
            style={{
              width: `${
                ((plates.length + (plates.length - 1)) /
                  (numberOfPlates + (numberOfPlates - 1))) *
                100
              }%`,
            }}
          >
            {/* Plates */}
            {plates.map((plateIndex) => (
              // Plate
              <div
                key={plateIndex}
                className="bg-gray-200"
                style={{
                  width: `${
                    (1 / (plates.length + (plates.length - 1))) * 100
                  }%`,
                }}
              />
            ))}

            {/* Overlay */}
            <div className="absolute inset-0 group p-2">
              {/* Color */}
              <div
                className="opacity-50 absolute inset-0"
                style={{
                  backgroundColor: percentageColors.getColor(
                    (percentage + 0.000001) * 10
                  ),
                }}
              />

              {/* Percentage */}
              <span className="relative z-10 lg:invisible lg:group-hover:visible inline-flex items-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm font-medium bg-white/70 text-gray-800">
                {round(percentage * 100, 2)}%
              </span>
            </div>

            {/* Sector index */}
            <div
              className="absolute top-full left-1/2 -translate-x-1/2 flex items-center text-center"
              style={{
                height: `${
                  (barWidth / frameHeight) * (frameHeight / platesHeight) * 100
                }%`,
              }}
            >
              <p className="font-mono text-gray-400 uppercase text-xs lg:text-lg font-bold">
                {int2roman(index)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterPlateHeatmap;
