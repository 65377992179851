import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePagination } from "../lib/usePagination";
import { FilterCloth } from "../models/filter-cloth";
import {
  Transaction as TransactionModel,
  TransactionContext,
} from "../models/transaction";
import Alert from "./alert";
import Pagination from "./pagination";
import Transaction from "./transaction";

interface TransactionsProps {
  transactions: TransactionModel[];
  context: TransactionContext;
  cloth?: FilterCloth;
}

const Transactions: React.FC<TransactionsProps> = ({
  transactions,
  context,
  cloth,
}) => {
  const { t } = useTranslation();

  // Pagination
  const [paginatedTransactions, setPaginatedTransactions] =
    useState<TransactionModel[]>(transactions);

  // Page size and page number
  const pageSize = 10;
  const totalNumberOfPages = Math.ceil(transactions.length / pageSize);

  const pagination = usePagination({
    total: totalNumberOfPages,
  });
  const [paginationCount, setPaginationCount] = useState({
    start: 1,
    end: transactions.length,
    total: transactions.length,
  });

  useEffect(() => {
    // Calculate start and end offsets (0-based)
    const start = (pagination.active - 1) * pageSize;
    const end = start + pageSize;

    // Slice transactions
    setPaginatedTransactions(transactions.slice(start, end));

    // Set pagination count values (1-based)
    setPaginationCount((count) => ({
      ...count,
      start: start + 1,
      end: Math.min(end, transactions.length),
    }));
  }, [pagination.active, transactions]);

  return (
    <>
      {!!paginatedTransactions.length ? (
        <div className="space-y-6">
          <Pagination pagination={pagination} count={paginationCount} />
          <ul>
            {paginatedTransactions.map((transaction, idx) => (
              <Transaction
                key={transaction.id}
                transactions={paginatedTransactions}
                transaction={transaction}
                context={context}
                idx={idx}
                cloth={cloth}
              />
            ))}
          </ul>
          <Pagination pagination={pagination} count={paginationCount} />
        </div>
      ) : (
        <Alert severity="info">
          <p>{t("transactions-zero")}</p>
        </Alert>
      )}
    </>
  );
};

export default Transactions;
