import {
  ArrowDownIcon,
  ArrowUpIcon,
  SwitchVerticalIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import { format } from "date-fns";
import { isInteger } from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { getDateFnsLocale, smartTrim } from "../lib/helpers";
import { FilterCloth } from "../models/filter-cloth";
import { Area } from "../models/heatmap";
import {
  Transaction as TransactionModel,
  TransactionContext,
} from "../models/transaction";
import { SelectedAreaContext } from "../routes/filter-plates/detail";
import Lightbox from "./lightbox";
import Link from "./link";

// Mini install/uninstall icon for cloth link
const renderMiniIcon = (type: "install" | "uninstall") => {
  const Icon = {
    install: ArrowUpIcon,
    uninstall: ArrowDownIcon,
  }[type];

  const iconBgColor = {
    install: "bg-green-500",
    uninstall: "bg-red-500",
  }[type];

  return (
    <span
      className={classNames(
        iconBgColor,
        "h-4 w-4 rounded-full flex items-center justify-center"
      )}
    >
      <Icon className="h-3 w-3 text-white" aria-hidden="true" />
    </span>
  );
};

interface TransactionProps {
  transaction: TransactionModel;
  idx: number;
  transactions: TransactionModel[];
  context: TransactionContext;
  cloth?: FilterCloth;
  selectedArea?: Area;
}

const Transaction: React.FC<TransactionProps> = ({
  transaction,
  idx,
  transactions,
  context,
  cloth,
}) => {
  const { t, i18n } = useTranslation();
  const locale = getDateFnsLocale(i18n.resolvedLanguage);

  const {
    type,
    createdAt,
    plate,
    side,
    newCloth,
    oldCloth,
    user,
    reason,
    highlightedAreas,
    comment,
    photo,
    cycleNumber,
    completedCycles,
  } = transaction;

  const selectedArea = useContext(SelectedAreaContext);

  // When viewed from a filter cloth, the transaction should display content based on role within replacement (old or new cloth)
  const isReplacementFor =
    context === "cloth" &&
    type === "replace" &&
    !!cloth &&
    !!oldCloth &&
    !!newCloth &&
    cloth?.id === newCloth.id;

  const isReplacedBy =
    context === "cloth" &&
    type === "replace" &&
    !!cloth &&
    !!oldCloth &&
    !!newCloth &&
    cloth?.id === oldCloth.id;

  // Icon and icon background
  const Icon = {
    install: ArrowUpIcon,
    uninstall: ArrowDownIcon,
    replace: isReplacementFor
      ? ArrowUpIcon
      : isReplacedBy
      ? ArrowDownIcon
      : SwitchVerticalIcon,
  }[type];

  const iconBgColor = {
    install: "bg-green-500",
    uninstall: "bg-red-500",
    replace: isReplacementFor
      ? "bg-green-500"
      : isReplacedBy
      ? "bg-red-500"
      : "bg-andritz-500",
  }[type];

  // Title
  const title = {
    cloth: {
      install: t("installed"),
      uninstall: t("uninstalled"),
      replace: isReplacementFor
        ? t("installed-as-replacement")
        : isReplacedBy
        ? t("uninstalled-and-replaced")
        : // shouldn't ever happen
          t("replaced"),
    },
    plate: {
      install: t("installed-filter-cloth"),
      uninstall: t("uninstalled-filter-cloth"),
      replace: t("replaced-filter-cloth"),
    },
    press: {
      install: t("installed-filter-cloth"),
      uninstall: t("uninstalled-filter-cloth"),
      replace: t("replaced-filter-cloth"),
    },
  }[context][type];

  // Cloth link with optional item
  const renderClothLink = (
    type: "install" | "uninstall",
    withIcon: boolean,
    cloth?: FilterCloth
  ) => {
    return cloth ? (
      <Link
        to={`/filter-cloths/detail?id=${cloth.id}`}
        className="text-andritz-500 hover:text-andritz-600 font-mono inline-flex items-center gap-1"
      >
        {/* Icon */}
        {withIcon && renderMiniIcon(type)}

        {smartTrim(cloth.fcid)}
      </Link>
    ) : null;
  };

  // Cloths
  const cloths = {
    cloth: {
      install: null,
      uninstall: null,
      replace: null,
    },
    plate: {
      install: renderClothLink("install", false, newCloth),
      uninstall: renderClothLink("uninstall", false, oldCloth),
      replace: (
        <div className="flex flex-col items-start">
          {renderClothLink("install", true, newCloth)}
          {renderClothLink("uninstall", true, oldCloth)}
        </div>
      ),
    },
    press: {
      install: renderClothLink("install", false, newCloth),
      uninstall: renderClothLink("uninstall", false, oldCloth),
      replace: (
        <div className="flex flex-col items-start">
          {renderClothLink("install", true, newCloth)}
          {renderClothLink("uninstall", true, oldCloth)}
        </div>
      ),
    },
  }[context][type];

  // Link would link to self on plates
  const showPlate = true || context !== "plate";

  const infoTable =
    type === "replace" || type === "uninstall"
      ? [
          {
            head: t("reason"),
            content: !isReplacementFor && !!reason && t(reason),
          },
          {
            head: t("highlighted-areas"),
            content:
              !isReplacementFor &&
              !!highlightedAreas?.length &&
              highlightedAreas
                .map<React.ReactNode>((area) => (
                  <span
                    key={area}
                    className={classNames("font-mono", {
                      "outline-2 outline-dashed outline-black/60 outline-offset-2":
                        area === selectedArea,
                    })}
                  >
                    {area}
                  </span>
                ))
                .reduce(
                  (total, curr) => (!!total ? [total, ", ", curr] : curr),
                  undefined
                ),
          },
          {
            head: t("photo"),
            content: !!photo && <Lightbox url={photo.url} />,
          },
          {
            head: t("completed-cycles"),
            content: isInteger(completedCycles) && completedCycles,
          },
        ].filter((row) => !!row.content || row.content === 0)
      : [];

  const selected =
    !isReplacementFor &&
    !!selectedArea &&
    !!highlightedAreas?.length &&
    highlightedAreas.includes(selectedArea);

  return (
    <li className="max-w-lg">
      <div
        className={classNames("relative", {
          "pb-10": idx !== transactions.length - 1,
        })}
      >
        {/* Line */}
        {idx !== transactions.length - 1 ? (
          <span
            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        ) : null}

        {/* Item */}
        <div
          className={classNames("relative flex items-start space-x-3", {
            "outline-2 outline-dashed outline-black/60 outline-offset-[6px]":
              selected,
          })}
        >
          {/* Icon */}
          <div>
            <span
              className={classNames(
                iconBgColor,
                "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
              )}
            >
              <Icon className="h-5 w-5 text-white" aria-hidden="true" />
            </span>
          </div>

          {/* Content */}
          <div className="min-w-0 flex-1 flex flex-col-reverse sm:flex-row sm:justify-between gap-px sm:gap-4">
            {/* Text */}
            <div>
              {/* Title */}
              <h3 className="text-sm font-medium text-gray-900">{title}</h3>

              {/* Replacement for */}
              {isReplacementFor && (
                <p className="mt-0.5 text-sm text-gray-500">
                  {t("for")} {renderClothLink("uninstall", false, oldCloth)}
                </p>
              )}

              {/* Replaced by */}
              {isReplacedBy && (
                <p className="mt-0.5 text-sm text-gray-500">
                  {t("by")} {renderClothLink("install", false, newCloth)}
                </p>
              )}

              {/* Cloth link (plate & press context) */}
              {!!cloths && (
                <div className="mt-0.5 text-sm text-gray-500">{cloths}</div>
              )}

              {/* Filter plate */}
              {showPlate && !!plate && (
                <p className="mt-0.5 text-sm text-gray-500">
                  {
                    {
                      install: t("on"),
                      uninstall: t("from"),
                      replace: t("on"),
                    }[type]
                  }{" "}
                  <Link
                    to={`/filter-plates/detail?id=${plate.id}`}
                    className="text-andritz-500 hover:text-andritz-600"
                  >
                    {plate.title}
                  </Link>{" "}
                  ({t(side)})
                </p>
              )}

              {/* User */}
              {!!user && (
                <p className="mt-0.5 text-sm text-gray-500">
                  {t("by")} {user.name}
                </p>
              )}

              {/* Info */}
              {!!infoTable?.length && (
                <table className="mt-2 text-sm text-gray-700">
                  <tbody className="align-top">
                    {infoTable.map(({ head, content }) => (
                      <tr key={head}>
                        <th className="py-px pl-0 pr-1 font-normal text-gray-500">
                          {head}
                        </th>
                        <td className="py-px pl-1 pr-0">{content}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {/* Comment */}
              {!isReplacementFor && !!comment && (
                <div className="mt-2 text-sm text-gray-700">
                  <h6 className="font-normal text-gray-500">{t("comment")}</h6>
                  <p className="whitespace-pre-wrap">{comment}</p>
                </div>
              )}
            </div>

            <div className="sm:text-right text-xs sm:text-sm whitespace-nowrap text-gray-500">
              {/* Datetime */}
              <time dateTime={createdAt}>
                {format(new Date(createdAt), "P, p", { locale })}
              </time>

              {/* Cycle number */}
              {(!!cycleNumber || cycleNumber === 0) && (
                <p className="mt-0.5 text-sm text-gray-500">
                  {t("cycle-number")} {cycleNumber}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Transaction;
