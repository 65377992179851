import React from "react";
import Alert from "../alert";

interface TrErrorProps {
  colSpan?: number;
}

const TrError: React.FC<TrErrorProps> = ({ colSpan = 99, children }) => {
  return (
    <tr className="bg-white">
      <td className="px-6 py-4 whitespace-nowrap" colSpan={colSpan}>
        <Alert severity="error">{children}</Alert>
      </td>
    </tr>
  );
};

export default TrError;
