import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import client from "./apollo-client";
import SignIn from "./components/sign-in";
import SignInGate from "./components/sign-in-gate";
import Layout from "./layouts";
import DashboardPage from "./routes";
import NoMatchPage from "./routes/404";
import AccountPage from "./routes/account";
import CustomersPage from "./routes/customers";
import CustomerPage from "./routes/customers/detail";
import FilterClothsPage from "./routes/filter-cloths";
import FilterClothPage from "./routes/filter-cloths/detail";
import FilterPlatesPage from "./routes/filter-plates";
import FilterPlatePage from "./routes/filter-plates/detail";
import FilterPressesPage from "./routes/filter-presses";
import FilterPressPage from "./routes/filter-presses/detail";
import LocationsPage from "./routes/locations";
import LocationPage from "./routes/locations/detail";
import "./styles/globals.css";

interface AppProps {}

const App: React.FC<AppProps> = () => {
  const { i18n } = useTranslation();
  return (
    <HelmetProvider>
      <Helmet>
        <html lang={i18n.resolvedLanguage}></html>
      </Helmet>

      <ApolloProvider client={client}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<SignIn />} />

            <Route
              element={
                <SignInGate>
                  <Layout />
                </SignInGate>
              }
            >
              <Route path="/" element={<DashboardPage />} />

              <Route path="/customers" element={<CustomersPage />} />
              <Route path="/customers/detail" element={<CustomerPage />} />

              <Route path="/locations" element={<LocationsPage />} />
              <Route path="/locations/detail" element={<LocationPage />} />

              <Route path="/filter-presses" element={<FilterPressesPage />} />
              <Route
                path="/filter-presses/detail"
                element={<FilterPressPage />}
              />

              <Route path="/filter-plates" element={<FilterPlatesPage />} />
              <Route
                path="/filter-plates/detail"
                element={<FilterPlatePage />}
              />

              <Route path="/filter-cloths" element={<FilterClothsPage />} />
              <Route
                path="/filter-cloths/detail"
                element={<FilterClothPage />}
              />

              <Route path="/account" element={<AccountPage />} />

              {/* Catch-all */}
              <Route path="*" element={<NoMatchPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </HelmetProvider>
  );
};

export default App;
