import { ApolloError } from "@apollo/client";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { findClothMode } from "../lib/helpers";
import { FilterPlate } from "../models/filter-plate";
import { FilterPress } from "../models/filter-press";
import Link from "./link";
import Table from "./table-elements/table";
import Td from "./table-elements/td";
import Th from "./table-elements/th";
import Tr from "./table-elements/tr";
import TrError from "./table-elements/tr-error";
import TrLoading from "./table-elements/tr-loading";
import TrZero from "./table-elements/tr-zero";

interface FilterPlatesTableProps {
  loading: boolean;
  error: ApolloError | undefined;
  filterPlates: FilterPlate[] | undefined;
}

const FilterPlatesTable: React.FC<FilterPlatesTableProps> = ({
  loading,
  error,
  filterPlates,
}) => {
  const { t, i18n } = useTranslation();

  const filterPressesMap = !!filterPlates
    ? [...filterPlates]
        // Sort by press title first
        .sort((a, b) => {
          // No filter press => item should be in the back
          if (!a.press) return 1;
          if (!b.press) return -1;
          // Press present, sort alphabetically
          return a.press.title.localeCompare(
            b.press.title,
            i18n.resolvedLanguage,
            { numeric: true }
          );
        })
        // Group by press
        .reduce(
          (intermediateFilterPressesMap, filterPlate) =>
            !!filterPlate.press
              ? intermediateFilterPressesMap.set(filterPlate.press, [
                  ...(intermediateFilterPressesMap.get(filterPlate.press) ||
                    []),
                  filterPlate,
                ])
              : intermediateFilterPressesMap,
          new Map<FilterPress, FilterPlate[]>()
        )
    : null;

  const sortFilterPlatesByIndex = (filterPlates: FilterPlate[]) =>
    filterPlates.sort((a, b) => a.plateIndex - b.plateIndex);

  return (
    <Table>
      <thead className="bg-gray-50">
        <tr>
          <Th>{t("name")}</Th>
          <Th>{t("plate-index")}</Th>
          <Th>{t("cloth-mode")}</Th>
          <Th>{t("cloth-installed")}</Th>
          <Th last={true}>
            <span className="sr-only">{t("view")}</span>
          </Th>
        </tr>
      </thead>

      {/* Render content or info/error state */}
      <tbody>
        {loading ? (
          // Loading state
          <TrLoading />
        ) : error ? (
          // Error state
          <TrError>
            <p>{error.toString()}</p>
          </TrError>
        ) : !!filterPressesMap?.size ? (
          // Table
          Array.from(filterPressesMap).map(([filterPress, filterPlates]) => (
            <React.Fragment key={filterPress?.id ?? "noFilterPress"}>
              {/* Filter press row */}
              <tr className="bg-gray-50 border-t border-b border-gray-200">
                <th
                  colSpan={99}
                  className="px-6 py-4 whitespace-nowrap text-sm text-left font-medium text-gray-900"
                >
                  <Link
                    to={`/filter-presses/detail?id=${filterPress?.id}`}
                    className="text-andritz-500 hover:text-andritz-600"
                  >
                    {filterPress.title}
                  </Link>
                </th>
              </tr>

              {/* Filter plates */}
              {sortFilterPlatesByIndex(filterPlates).map((filterPlate, idx) => (
                <Tr key={filterPlate.id} bgGray={idx % 2 === 1}>
                  <Td>
                    <span className="text-gray-400 mr-2">└</span>

                    <Link
                      to={`/filter-plates/detail?id=${filterPlate.id}`}
                      className="text-andritz-500 hover:text-andritz-600"
                    >
                      {filterPlate.title}
                    </Link>
                  </Td>
                  <Td>{filterPlate.plateIndex}</Td>
                  <Td>
                    {t(
                      findClothMode(
                        filterPlate.press.clothModeConfig,
                        filterPlate.plateIndex
                      ) || ""
                    )}
                  </Td>
                  <Td>
                    {filterPlate.clothInstalled ? (
                      <>
                        <span className="sr-only">{t("yes")}</span>
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      </>
                    ) : (
                      <>
                        <span className="sr-only">{t("no")}</span>
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Td>
                  <Td last={true}>
                    <Link
                      to={`/filter-plates/detail?id=${filterPlate.id}`}
                      className="font-medium text-andritz-500 hover:text-andritz-600"
                    >
                      {t("view")}
                    </Link>
                  </Td>
                </Tr>
              ))}
            </React.Fragment>
          ))
        ) : (
          // Zero state
          <TrZero>{t("filter-plates-zero")}</TrZero>
        )}
      </tbody>
    </Table>
  );
};

export default FilterPlatesTable;
