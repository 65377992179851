import { gql, useQuery } from "@apollo/client";
import { useLoadable } from "../../lib/useLoadable";
import { fragments as customerFragments } from "../../models/customer";
import { fragments as filterPlateFragments } from "../../models/filter-plate";
import { FilterPress, fragments } from "../../models/filter-press";
import { fragments as locationFragments } from "../../models/location";
import { selectedCustomerState } from "../../state";

export const GET_ALL_FILTER_PRESSES_QUERY = gql`
  query ALL_FILTER_PRESSES_QUERY($customerId: ID!) {
    filterPresses(
      orderBy: { title: asc }
      where: { location: { customer: { id: { equals: $customerId } } } }
    ) {
      ...FilterPress
      location {
        ...Location
        customer {
          ...Customer
        }
      }
    }
  }
  ${fragments.filterPress}
  ${locationFragments.location}
  ${customerFragments.customer}
`;

export const useGetAllFilterPresses = () => {
  // Get selected customer
  const {
    error: selectedCustomerError,
    loading: selectedCustomerLoading,
    result: selectedCustomer,
  } = useLoadable(selectedCustomerState);

  // Query filter presses
  const { loading, error, data } = useQuery<{
    filterPresses: FilterPress[];
  }>(GET_ALL_FILTER_PRESSES_QUERY, {
    variables: {
      customerId: selectedCustomer?.id,
    },
    skip: !selectedCustomer,
  });

  const filterPresses = data?.filterPresses;

  return {
    loading: loading || selectedCustomerLoading,
    error: error || selectedCustomerError,
    filterPresses,
  };
};

export const GET_FILTER_PRESS_QUERY = gql`
  query FILTER_PRESS_QUERY($id: ID!) {
    filterPress(where: { id: $id }) {
      ...FilterPress
      documentation {
        url
      }
      location {
        ...Location
        customer {
          ...Customer
        }
      }
      plates(orderBy: { plateIndex: asc }) {
        ...FilterPlate
        press {
          ...FilterPress
        }
      }
      sectors {
        index
        plates
        percentage
      }
      averageOperationTimePerPlate {
        plateIndex
        time
      }
      operationTimes {
        month {
          min
          max
          average
        }
        threeMonths {
          min
          max
          average
        }
      }
      upcomingReplacements {
        week
        month
      }
      recentInstalls {
        week
        month
      }
    }
  }
  ${fragments.filterPress}
  ${locationFragments.location}
  ${customerFragments.customer}
  ${filterPlateFragments.filterPlate}
  ${fragments.filterPress}
`;

export const useGetFilterPress = (id?: string) => {
  const { loading, error, data } = useQuery<{
    filterPress: FilterPress;
  }>(GET_FILTER_PRESS_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });

  const filterPress = data?.filterPress;

  return {
    loading,
    error,
    filterPress,
  };
};
