import classNames from "classnames";
import Gradient from "javascript-color-gradient";
import { round } from "lodash";
import { useContext } from "react";
import colors from "tailwindcss/colors";
import { areas } from "../lib/helpers";
import { Area } from "../models/heatmap";
import { SelectedAreaContext } from "../routes/filter-plates/detail";

interface HeatmapProps {
  highlightedAreas?: Area[];
  toggleSelectedArea?: (area: Area) => void;
  heatmapAreas?: {
    area: Area;
    percentage: number;
  }[];
}

const Heatmap: React.FC<HeatmapProps> = ({
  highlightedAreas,
  heatmapAreas,
  toggleSelectedArea,
}) => {
  const selectedArea = useContext(SelectedAreaContext);

  const mode = !!heatmapAreas?.length ? "heatmap" : "highlighted";

  const extendedHighlightedAreas = areas.map((area) => ({
    area,
    highlighted: !!highlightedAreas?.includes(area),
    selected: !!selectedArea && selectedArea === area,
    percentage:
      heatmapAreas?.find(({ area: heatmapArea }) => heatmapArea === area)
        ?.percentage ?? 0,
  }));

  // Set up percentage colors
  const percentageColors = new Gradient();
  percentageColors.setGradient(
    colors.emerald[300],
    colors.amber[300],
    colors.red[300]
  );

  return (
    <div className="heatmap-wrapper">
      <div className="aspect-w-1 aspect-h-1">
        {/* Coordinates */}
        <div className="font-mono text-gray-400 uppercase text-lg font-bold">
          {/* X axis */}
          <div className="absolute inset-0 h-full w-full pl-8 grid grid-cols-3 text-center">
            <p>A</p>
            <p>B</p>
            <p>C</p>
          </div>

          {/* Y axis */}
          <div className="flex flex-col h-full pt-8">
            <div className="grow flex items-center">
              <p>1</p>
            </div>

            <div className="grow flex items-center">
              <p>2</p>
            </div>

            <div className="grow flex items-center">
              <p>3</p>
            </div>
          </div>
        </div>

        <div>
          <div className="absolute left-8 top-8 right-0 bottom-0">
            {/* Background SVG */}
            <div className="absolute inset-0 rounded-md overflow-hidden">
              <svg
                className="w-full h-full fill-gray-200"
                xmlns="http://www.w3.org/2000/svg"
                width={256}
                height={256}
                viewBox="0 0 67.733 67.733"
              >
                <path
                  strokeWidth={1.97812116}
                  d="M0 0v256h256V0H0zm48 16h160l32 32v160l-32 32H48l-32-32V48l32-32zm80 96a16 16 0 0 0-16 16 16 16 0 0 0 16 16 16 16 0 0 0 16-16 16 16 0 0 0-16-16z"
                  transform="scale(.26458)"
                />
              </svg>
            </div>

            {/* Color & border overlay */}
            <div className="absolute inset-0 h-full grid grid-cols-3 rounded-md overflow-hidden">
              {extendedHighlightedAreas.map(
                ({ area, highlighted, percentage }, index) => (
                  <div
                    key={area}
                    className={classNames("relative border-white", {
                      "border-r": index % 3 < 2,
                      "border-b": index < 6,
                    })}
                  >
                    <div
                      className={classNames(
                        "absolute inset-0 opacity-50",
                        mode === "highlighted" &&
                          (highlighted ? "bg-red-300" : "bg-green-300")
                      )}
                      style={
                        mode === "heatmap"
                          ? {
                              backgroundColor: percentageColors.getColor(
                                (percentage + Number.EPSILON) * 10
                              ),
                            }
                          : undefined
                      }
                    />
                  </div>
                )
              )}
            </div>

            {/* Action overlay */}
            {mode === "heatmap" && (
              <div className="absolute inset-0">
                <div className="h-full grid grid-cols-3">
                  {extendedHighlightedAreas.map(
                    ({ area, selected, percentage }, index) => (
                      <button
                        key={area}
                        disabled={percentage <= 0}
                        onClick={() => {
                          percentage > 0 &&
                            !!toggleSelectedArea &&
                            toggleSelectedArea(area);
                        }}
                        className={classNames(
                          "flex justify-start items-start group p-2",
                          {
                            // state
                            "outline-2 outline-dashed outline-black/60 outline-offset-2":
                              selected,
                            "cursor-pointer": percentage > 0,
                            // rounded edges
                            "rounded-tl-md": index === 0,
                            "rounded-tr-md": index === 2,
                            "rounded-bl-md": index === 6,
                            "rounded-br-md": index === 8,
                          }
                        )}
                      >
                        {/* Percentage value */}
                        <span className="invisible group-hover:visible inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-white/70 text-gray-800">
                          {round(percentage * 100, 2)}%
                        </span>
                      </button>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Heatmap;
