import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import React from "react";
import { FilterPress } from "../models/filter-press";
import Link from "./link";
import StatusBadge from "./status-badge";
import Table from "./table-elements/table";
import Td from "./table-elements/td";
import Th from "./table-elements/th";
import Tr from "./table-elements/tr";
import TrError from "./table-elements/tr-error";
import TrLoading from "./table-elements/tr-loading";
import TrZero from "./table-elements/tr-zero";

interface FilterPressesTableProps {
  loading: boolean;
  error: ApolloError | undefined;
  filterPresses: FilterPress[] | undefined;
}

const FilterPressesTable: React.FC<FilterPressesTableProps> = ({
  loading,
  error,
  filterPresses,
}) => {
  const { t } = useTranslation();

  return (
    <Table>
      <thead className="bg-gray-50">
        <tr>
          <Th>{t("name")}</Th>
          <Th>{t("status")}</Th>
          <Th>{t("location")}</Th>
          <Th last={true}>
            <span className="sr-only">{t("view")}</span>
          </Th>
        </tr>
      </thead>

      {/* Render content or info/error state */}
      <tbody>
        {loading ? (
          // Loading state
          <TrLoading />
        ) : error ? (
          // Error state
          <TrError>
            <p>{error.toString()}</p>
          </TrError>
        ) : !!filterPresses?.length ? (
          // Table
          filterPresses.map((filterPress, idx) => (
            <Tr key={filterPress.id} bgGray={idx % 2 === 1}>
              <Td>
                <Link
                  to={`/filter-presses/detail?id=${filterPress.id}`}
                  className="text-andritz-500 hover:text-andritz-600"
                >
                  {filterPress.title}
                </Link>
              </Td>
              <Td>
                <StatusBadge status={filterPress.isActive} type="active" />
              </Td>
              <Td>
                {!!filterPress?.location && (
                  <Link
                    to={`/locations/detail?id=${filterPress.location.id}`}
                    className="text-andritz-500 hover:text-andritz-600"
                  >
                    {filterPress.location.name}
                  </Link>
                )}
              </Td>
              <Td last={true}>
                <Link
                  to={`/filter-presses/detail?id=${filterPress.id}`}
                  className="font-medium text-andritz-500 hover:text-andritz-600"
                >
                  {t("view")}
                </Link>
              </Td>
            </Tr>
          ))
        ) : (
          // Zero state
          <TrZero>{t("filter-presses-zero")}</TrZero>
        )}
      </tbody>
    </Table>
  );
};

export default FilterPressesTable;
