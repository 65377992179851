import { gql, useMutation } from "@apollo/client";
import { FilterPress } from "../../models/filter-press";

// Clear filter press data
const CLEAR_FILTER_PRESS_DATA_MUTATION = gql`
  mutation CLEAR_FILTER_PRESS_DATA_MUTATION(
    $where: FilterPressWhereUniqueInput!
  ) {
    clearFilterPressData(where: $where) {
      id
    }
  }
`;

export const useClearFilterPressData = (filterPress?: FilterPress) =>
  useMutation(CLEAR_FILTER_PRESS_DATA_MUTATION, {
    variables: {
      where: {
        id: filterPress?.id,
      },
    },
    refetchQueries: "active",
    awaitRefetchQueries: true,
  });
