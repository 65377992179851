import { useState } from "react";

export const useSearch = <T>(
  objects: T[] | undefined,
  fields: (object: T) => string[]
) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const searchedObjects = !!objects
    ? objects.filter((object) =>
        fields(object).some((fieldValue) =>
          fieldValue.toLowerCase().includes(searchTerm.trim().toLowerCase())
        )
      )
    : objects;

  const searchChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setSearchTerm(e.target.value);
  };

  return {
    searchedObjects,
    searchTerm,
    searchChangeHandler,
  };
};
