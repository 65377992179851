import classnames from "classnames";
import React, { ReactNode } from "react";
import { BreadcrumbItem, Breadcrumb } from "./breadcrumb";

interface HeaderProps {
  title: string | ReactNode;
  Icon?: (props: React.ComponentProps<"svg">) => JSX.Element;
  actions?: ReactNode;
  meta?: ReactNode;
  breadcrumb?: BreadcrumbItem[];
}

const Header: React.FC<HeaderProps> = ({
  title,
  Icon,
  actions,
  breadcrumb = [],
  meta,
}) => {
  return (
    <div className="px-4 sm:px-6 md:px-8 py-6 bg-white shadow">
      {/* Breadcrumb */}
      {!!breadcrumb.length && <Breadcrumb breadcrumb={breadcrumb} />}

      {/* Heading bar */}
      <div
        className={classnames(
          {
            "mt-2": !!breadcrumb.length,
          },
          "md:flex md:items-center md:justify-between"
        )}
      >
        {/* Title (with optional icon) */}
        <div className="flex-1 min-w-0 ">
          <div className="flex items-center">
            {/* {!!Icon && (
            <Icon
              className="mr-3 h-6 w-6 sm:h-7 sm:w-7 text-gray-500"
              aria-hidden="true"
            />
          )} */}
            <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {title}
            </h1>
          </div>

          {/* Meta content */}
          {!!meta && (
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              {meta}
            </div>
          )}
        </div>

        {/* Actions */}
        {!!actions && (
          <div className="mt-4 shrink-0 flex md:mt-0 md:ml-4">{actions}</div>
        )}
      </div>
    </div>
  );
};

export default Header;
