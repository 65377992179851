import { ExclamationIcon } from "@heroicons/react/solid";
import { XCircleIcon } from "@heroicons/react/solid";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { InformationCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";

interface AlertProps {
  severity?: "error" | "warning" | "success" | "info";
  headline?: string;
}

const Alert: React.FC<AlertProps> = ({
  children,
  severity = "warning",
  headline,
}) => {
  // Choose correct icon
  const Icon = {
    warning: ExclamationIcon,
    error: XCircleIcon,
    success: CheckCircleIcon,
    info: InformationCircleIcon,
  }[severity];

  return (
    <div
      className={classNames(
        {
          "bg-yellow-50": severity === "warning",
          "bg-red-50": severity === "error",
          "bg-green-50": severity === "success",
          "bg-blue-50": severity === "info",
        },
        "rounded-md p-4"
      )}
    >
      <div className="flex">
        {/* Icon */}
        <div className="shrink-0">
          <Icon
            className={classNames(
              {
                "text-yellow-400": severity === "warning",
                "text-red-400": severity === "error",
                "text-green-400": severity === "success",
                "text-andritz-500": severity === "info",
              },
              "h-5 w-5"
            )}
            aria-hidden="true"
          />
        </div>

        {/* Content */}
        <div className="ml-3">
          {/* Headline */}
          {headline && (
            <h3
              className={classNames(
                {
                  "text-yellow-800": severity === "warning",
                  "text-red-800": severity === "error",
                  "text-green-800": severity === "success",
                  "text-andritz-600": severity === "info",
                },
                "text-sm font-medium"
              )}
            >
              {headline}
            </h3>
          )}

          {/* Description */}
          <div
            className={classNames(
              {
                "text-yellow-700": severity === "warning",
                "text-red-700": severity === "error",
                "text-green-700": severity === "success",
                "text-andritz-600": severity === "info",
                "mt-2": !!headline,
              },
              "text-sm"
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
