import { gql, useQuery } from "@apollo/client";
import { useLoadable } from "../../lib/useLoadable";
import { fragments as customerFragments } from "../../models/customer";
import { fragments as filterClothFragments } from "../../models/filter-cloth";
import { FilterPlate, fragments } from "../../models/filter-plate";
import { fragments as filterPressFragments } from "../../models/filter-press";
import { fragments as locationFragments } from "../../models/location";
import { selectedCustomerState } from "../../state";

export const GET_ALL_FILTER_PLATES_QUERY = gql`
  query ALL_FILTER_PLATES_QUERY($customerId: ID!) {
    filterPlates(
      where: {
        press: { location: { customer: { id: { equals: $customerId } } } }
      }
    ) {
      ...FilterPlate
      press {
        ...FilterPress
      }
    }
  }
  ${fragments.filterPlate}
  ${filterPressFragments.filterPress}
`;

export const useGetAllFilterPlates = () => {
  // Get selected customer
  const {
    error: selectedCustomerError,
    loading: selectedCustomerLoading,
    result: selectedCustomer,
  } = useLoadable(selectedCustomerState);

  // Query filter plates
  const { loading, error, data } = useQuery<{
    filterPlates: FilterPlate[];
  }>(GET_ALL_FILTER_PLATES_QUERY, {
    variables: {
      customerId: selectedCustomer?.id,
    },
    skip: !selectedCustomer,
  });

  const filterPlates = data?.filterPlates;

  return {
    loading: loading || selectedCustomerLoading,
    error: error || selectedCustomerError,
    filterPlates,
  };
};

export const GET_FILTER_PLATE_QUERY = gql`
  query FILTER_PLATE_QUERY($id: ID!) {
    filterPlate(where: { id: $id }) {
      ...FilterPlate
      heatmapAreas {
        area
        percentage
      }
      press {
        ...FilterPress
        location {
          ...Location
          customer {
            ...Customer
          }
        }
      }
      cloth {
        ...FilterCloth
      }
      clothFront {
        ...FilterCloth
      }
      clothBack {
        ...FilterCloth
      }
    }
  }
  ${filterClothFragments.filterCloth}
  ${fragments.filterPlate}
  ${filterPressFragments.filterPress}
  ${locationFragments.location}
  ${customerFragments.customer}
`;

export const useGetFilterPlate = (id?: string) => {
  const { loading, error, data } = useQuery<{
    filterPlate: FilterPlate;
  }>(GET_FILTER_PLATE_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });

  const filterPlate = data?.filterPlate;

  return {
    loading,
    error,
    filterPlate,
  };
};

export const GET_FILTER_PLATES_CHART_DATA_QUERY = gql`
  query FILTER_PLATES_CHART_DATA_QUERY($pressId: ID!) {
    filterPlates(
      where: { press: { id: { equals: $pressId } } }
      orderBy: { plateIndex: asc }
    ) {
      ...FilterPlate
      cloth {
        ...FilterCloth
      }
      clothFront {
        ...FilterCloth
      }
      clothBack {
        ...FilterCloth
      }
    }
  }
  ${filterClothFragments.filterCloth}
  ${fragments.filterPlate}
`;

export const useGetFilterPlatesChartData = (pressId?: string) => {
  const { loading, error, data } = useQuery<{
    filterPlates: FilterPlate[];
  }>(GET_FILTER_PLATES_CHART_DATA_QUERY, {
    variables: {
      pressId,
    },
    skip: !pressId,
  });

  const filterPlates = data?.filterPlates;

  return {
    loading,
    error,
    filterPlates,
  };
};
