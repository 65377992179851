import { CogIcon } from "@heroicons/react/outline";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Header from "../components/header";
import LangSwitch from "../components/lang-switch";
import { useUser } from "../operations/queries/user";

const AccountPage: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  if (!user) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{user.name} | Smart Filtercloth</title>
      </Helmet>

      <Header title={user.name} Icon={CogIcon} />

      <div className="px-4 sm:px-6 md:px-8 py-6">
        <div className="space-y-6">
          {/* Language & time */}
          <AccountsPageCard headline={t("headline-language")}>
            <>
              <div>
                <p className="block text-sm font-medium text-gray-700">
                  {t("timezone")}
                </p>
                <p className="text-sm">
                  {Intl.DateTimeFormat().resolvedOptions().timeZone}
                </p>
              </div>

              <div>
                <LangSwitch />
              </div>
            </>
          </AccountsPageCard>
        </div>
      </div>
    </>
  );
};

export default AccountPage;

interface AccountsPageCardProps {
  headline: string;
}

const AccountsPageCard: React.FC<AccountsPageCardProps> = ({
  children,
  headline,
}) => {
  return (
    <div className="shadow rounded-md bg-white max-w-lg">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {headline}
        </h3>
      </div>

      <div className="px-4 py-5 sm:px-6 space-y-4">{children}</div>
    </div>
  );
};
