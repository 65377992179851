import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

type Language = {
  locale: string;
  name: string;
  flag: string;
};

const languages: Language[] = [
  {
    locale: "en",
    name: "english",
    flag: "🇺🇸",
  },
  {
    locale: "de",
    name: "german",
    flag: "🇩🇪",
  },
  {
    locale: "es",
    name: "spanish",
    flag: "🇪🇸",
  },
  {
    locale: "pt",
    name: "portuguese",
    flag: "🇵🇹",
  },
];

// Finds a language by locale, falls back to default (english)
const findLanguage = (localeToFind: string | undefined): Language =>
  languages.find(({ locale }) => locale === localeToFind) ?? languages[0];

export default function LangSwitch() {
  const { t, i18n } = useTranslation();

  const [selected, setSelected] = useState<Language>(
    findLanguage(i18n.resolvedLanguage)
  );

  // Select change handler
  const switchLanguage = (language: Language) => {
    // Set UI state
    setSelected(language);

    // Set i18n state
    i18n.changeLanguage(language.locale);
  };

  return (
    <Listbox value={selected} onChange={switchLanguage}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            {t("change-language")}
          </Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-andritz-500 focus:border-andritz-500 sm:text-sm">
              <span className="flex items-center">
                {selected.flag}
                <span className="ml-3 block truncate">{t(selected.name)}</span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {languages.map((language) => (
                  <Listbox.Option
                    key={language.locale}
                    className={({ active }) =>
                      classnames(
                        active ? "text-white bg-andritz-500" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={language}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {language.flag}
                          <span
                            className={classnames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {t(language.name)}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classnames(
                              active ? "text-white" : "text-andritz-500",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
