import { SearchIcon } from "@heroicons/react/solid";
import React from "react";
import { useTranslation } from "react-i18next";

interface TableSearchProps {
  searchChangeHandler: React.ChangeEventHandler<HTMLInputElement>;
  searchTerm: string;
}

const TableSearch: React.FC<TableSearchProps> = ({
  searchChangeHandler,
  searchTerm,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex rounded-md shadow-sm">
      <div className="relative flex items-stretch grow focus-within:z-10">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="search"
          name="search"
          id="search"
          value={searchTerm}
          className="focus:ring-andritz-500 focus:border-andritz-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
          placeholder={t("search-table")}
          onChange={searchChangeHandler}
        />
      </div>
    </div>
  );
};

export default TableSearch;
