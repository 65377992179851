import classNames from "classnames";
import React from "react";
import CircularProgress from "./circular-progress";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  loading?: boolean;
  icon?: React.ReactElement;
  block?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  text,
  icon,
  loading = false,
  className,
  children,
  block = false,
  ...props
}) => {
  const content = text ?? children;

  return (
    <button
      className={classNames(
        className,
        block ? "w-full flex" : "inline-flex",
        "justify-center gap-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-andritz-500 hover:bg-andritz-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-andritz-500"
      )}
      {...props}
    >
      {icon}
      {content}
      {loading && <CircularProgress className="-mr-1" color="text-white" />}
    </button>
  );
};

export default Button;
