import { Popover } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";

interface CardTooltipProps {}

const CardTooltip: React.FC<CardTooltipProps> = ({ children }) => {
  const { t } = useTranslation();

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "auto",
  });

  return (
    <Popover>
      <Popover.Button
        ref={setReferenceElement}
        className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600"
      >
        <span className="sr-only">{t("more-info")}</span>
        <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="w-screen max-w-sm z-10"
      >
        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="relative bg-white p-3">{children}</div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default CardTooltip;
