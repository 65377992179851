import React from "react";
import { useGetTransactions } from "../operations/queries/transactions";
import Alert from "./alert";
import CircularProgress from "./circular-progress";
import Transactions from "./transactions";

interface FilterPressTransactionsProps {
  pressId: string;
  limit?: number;
}

const FilterPressTransactions: React.FC<FilterPressTransactionsProps> = ({
  pressId,
  limit,
}) => {
  // Load transactions that affected the current press
  const { loading, error, transactions } = useGetTransactions(
    {
      plate: {
        press: {
          id: {
            equals: pressId,
          },
        },
      },
    },
    limit
  );

  return (
    <div className="space-y-5">
      {/* Error alert */}
      {!!error && (
        <Alert severity="error">
          <p>{error.message}</p>
        </Alert>
      )}

      {/* Transactions */}
      {loading ? (
        <CircularProgress />
      ) : !!transactions ? (
        <Transactions transactions={transactions} context="press" />
      ) : null}
    </div>
  );
};

export default FilterPressTransactions;
