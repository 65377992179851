import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  UiFilterChecks,
  UiFilterGroup,
  UiFilterItem,
  UiFilterState,
} from "../models/ui-filters";

export const useUiFilters = <T>(
  objects: T[] | undefined,
  setup: () => UiFilterGroup[],
  filterChecks: UiFilterChecks<T>
) => {
  const [filterState, setFilterState] = useState<UiFilterState>();
  const [filterGroups, setFilterGroups] = useState<UiFilterGroup[]>([]);

  useEffect(() => {
    const newFilterGroups = setup();
    setFilterGroups(newFilterGroups);

    // Transform filter groups (UiFilterGroup[]) into a filter state object (UiFilterState)
    setFilterState(
      newFilterGroups.reduce<UiFilterState>(
        (intermediateFilterState, filterGroup) => ({
          ...intermediateFilterState,
          [filterGroup.id]: filterGroup.filters.reduce(
            (intermediateFilterGroupState, filter) => ({
              ...intermediateFilterGroupState,
              [filter.value]: false,
            }),
            {}
          ),
        }),
        {}
      )
    );
  }, [setup]);

  // The actual filtering (AND across filter groups, OR within filter groups)
  const filteredObjects = objects?.filter((object) =>
    filterState
      ? Object.entries(filterState).every(
          ([filterStateGroupKey, filterStateGroupItems]) => {
            // Ignore non-checked filter items
            const checkedFilterItems = Object.entries(
              filterStateGroupItems
            ).filter(([_, filterStateItemChecked]) => filterStateItemChecked);

            // No filters selected within group => filter passes
            // Filters selected within group => check filter items
            return checkedFilterItems.length
              ? checkedFilterItems.some(
                  ([filterStateItemKey]) =>
                    filterChecks(object, filterStateItemKey)[
                      filterStateGroupKey
                    ] ?? true
                )
              : true;
          }
        )
      : objects
  );

  // Handler for filter checkboxes, mutating filter state on change
  const filterChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>, filterGroupKey: string) => {
      const filterItemKey = e.target.value;
      const checked = e.target.checked;

      // Extend old state with toggled filter item
      setFilterState((prevFilterGroups) =>
        !!prevFilterGroups && !!prevFilterGroups[filterGroupKey]
          ? {
              ...prevFilterGroups,
              [filterGroupKey]: {
                ...prevFilterGroups[filterGroupKey],
                [filterItemKey]: checked,
              },
            }
          : prevFilterGroups
      );
    },
    [setFilterState]
  );

  return {
    filteredObjects,
    filterState,
    setFilterState,
    filterGroups,
    setFilterGroups,
    filterChangeHandler,
  };
};

// Group filter items by their optional item group
export const groupFiltersByItemGroup = (filters: UiFilterItem[]) =>
  filters.reduce<{
    [key: string]: UiFilterItem[];
  }>(
    (intermediateItemGroups, filterItem) => ({
      ...intermediateItemGroups,
      [filterItem.itemGroup ?? "_no-group"]: [
        ...(intermediateItemGroups[filterItem.itemGroup ?? "_no-group"] ?? []),
        filterItem,
      ],
    }),
    {}
  );
