import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import CustomersTable from "../components/customers-table";
import Header from "../components/header";

const CustomersPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("customer", { count: 2 })} | Smart Filtercloth</title>
      </Helmet>

      <Header title={t("customer", { count: 2 })} />

      <div className="px-4 sm:px-6 md:px-8 py-6">
        <div className="max-w-screen-2xl">
          {/* Actions & list */}
          <div className="shadow rounded-md bg-white px-4 py-5 sm:px-6 space-y-4">
            <CustomersTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomersPage;
