import { gql, useQuery } from "@apollo/client";
import {
  Location,
  fragments as locationFragments,
} from "../../models/location";
import { fragments as customerFragments } from "../../models/customer";
import { fragments as filterPressFragments } from "../../models/filter-press";
import { useLoadable } from "../../lib/useLoadable";
import { selectedCustomerState } from "../../state";

export const GET_ALL_LOCATIONS_QUERY = gql`
  query ALL_LOCATIONS_QUERY($customerId: ID!) {
    locations(
      orderBy: { name: asc }
      where: { customer: { id: { equals: $customerId } } }
    ) {
      ...Location
      customer {
        ...Customer
        locations {
          id
          name
        }
      }
    }
  }
  ${customerFragments.customer}
  ${locationFragments.location}
`;

export const useGetAllLocations = () => {
  // Get selected customer
  const {
    error: selectedCustomerError,
    loading: selectedCustomerLoading,
    result: selectedCustomer,
  } = useLoadable(selectedCustomerState);

  // Query locations
  const { loading, error, data } = useQuery<{
    locations: Location[];
  }>(GET_ALL_LOCATIONS_QUERY, {
    variables: {
      customerId: selectedCustomer?.id,
    },
    skip: !selectedCustomer,
  });

  const locations = data?.locations;

  return {
    loading: loading || selectedCustomerLoading,
    error: error || selectedCustomerError,
    locations,
  };
};

export const GET_LOCATION_QUERY = gql`
  query LOCATION_QUERY($id: ID!) {
    location(where: { id: $id }) {
      ...Location
      customer {
        ...Customer
        locations {
          id
          name
        }
      }
      presses {
        ...FilterPress
      }
    }
  }
  ${locationFragments.location}
  ${customerFragments.customer}
  ${filterPressFragments.filterPress}
`;

export const useGetLocation = (id?: string) => {
  const { loading, error, data } = useQuery<{
    location: Location;
  }>(GET_LOCATION_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });

  const location = data?.location;

  return {
    loading,
    error,
    location,
  };
};
