import { gql } from "@apollo/client";
import { FilterCloth } from "./filter-cloth";
import { FilterPress } from "./filter-press";
import { HeatmapPercentage } from "./heatmap";

export interface FilterPlate {
  id: string;
  title: string;
  plateIndex: number;
  press: FilterPress;
  cloth?: FilterCloth;
  clothFront?: FilterCloth;
  clothBack?: FilterCloth;
  clothInstalled: boolean;
  heatmapAreas?: HeatmapPercentage[];
}
export type FilterPlateSide = "front" | "back" | "single";

export const fragments = {
  filterPlate: gql`
    fragment FilterPlate on FilterPlate {
      id
      title
      plateIndex
      clothInstalled
    }
  `,
};
