import { gql, useQuery } from "@apollo/client";
import {
  Customer,
  fragments as customerFragments,
} from "../../models/customer";
import { fragments as locationFragments } from "../../models/location";

export const GET_ALL_CUSTOMERS_QUERY = gql`
  query ALL_CUSTOMERS_QUERY {
    customers(orderBy: { name: asc }) {
      ...Customer
    }
  }
  ${customerFragments.customer}
`;

export const useGetAllCustomers = () => {
  const { loading, error, data } = useQuery<{
    customers: Customer[];
  }>(GET_ALL_CUSTOMERS_QUERY);

  const customers = data?.customers;

  return {
    loading,
    error,
    customers,
  };
};

export const GET_CUSTOMER_QUERY = gql`
  query CUSTOMER_QUERY($id: ID!) {
    customer(where: { id: $id }) {
      ...Customer
      locations {
        ...Location
        customer {
          id
          name
        }
      }
    }
  }
  ${customerFragments.customer}
  ${locationFragments.location}
`;

export const useGetCustomer = (id?: string) => {
  const { loading, error, data } = useQuery<{
    customer: Customer;
  }>(GET_CUSTOMER_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });

  const customer = data?.customer;

  return {
    loading,
    error,
    customer,
  };
};
