import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { FilterPress } from "../models/filter-press";

interface SelectFilterPressProps {
  filterPresses: FilterPress[];
  selectedFilterPress: FilterPress;
  setSelectedFilterPress: React.Dispatch<
    React.SetStateAction<FilterPress | undefined>
  >;
}

const SelectFilterPress: React.FC<SelectFilterPressProps> = ({
  filterPresses,
  selectedFilterPress,
  setSelectedFilterPress,
}) => {
  const { t } = useTranslation();

  return (
    <Listbox
      value={selectedFilterPress.id}
      onChange={(id) =>
        setSelectedFilterPress(
          filterPresses.find((filterPress) => filterPress.id === id)
        )
      }
    >
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            {t("choose-filter-press")}
          </Listbox.Label>

          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-4 pr-11 py-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-andritz-500 focus:border-andritz-500">
              <div className="flex items-center">
                {/* Active status indicator */}
                <span
                  aria-label={
                    selectedFilterPress.isActive ? t("active") : t("not-active")
                  }
                  className={classNames(
                    selectedFilterPress.isActive
                      ? "bg-green-400"
                      : "bg-red-400",
                    "flex-shrink-0 inline-block h-2 w-2 rounded-full"
                  )}
                />

                {/* Title & location */}
                <span className="ml-4 w-full inline-flex truncate">
                  <span className="truncate">{selectedFilterPress.title}</span>
                  {!!selectedFilterPress.location && (
                    <span className="ml-3 truncate text-gray-500">
                      {selectedFilterPress.location?.name}
                    </span>
                  )}
                </span>
              </div>

              {/* Selected indicator */}
              <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
                {filterPresses.map((filterPress) => (
                  <Listbox.Option
                    key={filterPress.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-andritz-500" : "text-gray-900",
                        "cursor-default select-none relative py-3 pl-4 pr-9"
                      )
                    }
                    value={filterPress.id}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {/* Active status indicator */}
                          <span
                            className={classNames(
                              filterPress.isActive
                                ? "bg-green-400"
                                : "bg-red-400",
                              "flex-shrink-0 inline-block h-2 w-2 rounded-full"
                            )}
                            aria-hidden="true"
                          />

                          {/* Title & location */}
                          <div className="ml-4 w-full inline-flex truncate">
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "truncate"
                              )}
                            >
                              {filterPress.title}
                            </span>
                            {!!filterPress.location && (
                              <span
                                className={classNames(
                                  active ? "text-blue-200" : "text-gray-500",
                                  "ml-3 truncate"
                                )}
                              >
                                {filterPress.location.name}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Selected indicator */}
                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-andritz-500",
                              "absolute inset-y-0 right-0 flex items-center pr-3"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default SelectFilterPress;
