import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { signIn } from "../operations/mutations/user";
import { useUser } from "../operations/queries/user";
import Alert from "./alert";
import Button from "./button";

interface SignInProps {}

const SignIn: React.FC<SignInProps> = () => {
  const { t } = useTranslation();

  // Redirect if already logged in
  const { user } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    !!user && navigate("/", { replace: true });
  }, [user, navigate]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function handleClick() {
    // Reset state
    setError("");
    setLoading(true);

    // Start the signin flow
    const { status } = await signIn();

    // Handle signin method returning an error
    if (status === "error") {
      setLoading(false);
      setError(t("error-occured"));
    }
  }

  return (
    <div className="min-h-[60vh] flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src="/images/andritz-logo.svg"
              alt="Andritz"
            />
            <h2 className="mt-3 text-center text-sm uppercase font-medium text-gray-700">
              Smart Filtercloth
            </h2>
          </div>

          <div className="mt-8 space-y-6">
            {/* Auth error */}
            {!!error && (
              <Alert severity="error">
                <p>{error}</p>
              </Alert>
            )}

            {/* Signin CTA */}
            <Button
              text={t("sign-in")}
              block={true}
              loading={loading}
              onClick={handleClick}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path d="M0 32h214.6v214.6H0V32zm233.4 0H448v214.6H233.4V32zM0 265.4h214.6V480H0V265.4zm233.4 0H448V480H233.4V265.4z" />
                </svg>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
