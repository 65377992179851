import { isString } from "lodash";
import { AtomEffect } from "recoil";

export function localStorageEffect<T>(key: string, type: T): AtomEffect<T> {
  return ({ setSelf, onSet }) => {
    // read possibly stored value from local storage (supports Maps)
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(
        isString(type)
          ? savedValue
          : type instanceof Map
          ? new Map(JSON.parse(savedValue))
          : JSON.parse(savedValue)
      );
    }

    onSet((newValue, _, isReset) => {
      // handle reset
      if (isReset) {
        localStorage.removeItem(key);
      } else {
        // store new value in local storage (supports Maps)
        localStorage.setItem(
          key,
          isString(newValue)
            ? newValue
            : JSON.stringify(
                newValue instanceof Map ? Array.from(newValue) : newValue
              )
        );
      }
    });
  };
}
