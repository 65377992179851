import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function NoMatchPage() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>404 | Smart Filtercloth</title>
      </Helmet>

      <div className="min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-andritz-500 sm:text-5xl">
              404
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  {t("404-headline")}
                </h1>
                <p className="mt-1 text-base text-gray-500">{t("404-text")}</p>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
