import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import React from "react";
import { Customer } from "../models/customer";
import { Location } from "../models/location";
import Link from "./link";
import Table from "./table-elements/table";
import Td from "./table-elements/td";
import Th from "./table-elements/th";
import Tr from "./table-elements/tr";
import TrError from "./table-elements/tr-error";
import TrLoading from "./table-elements/tr-loading";
import TrZero from "./table-elements/tr-zero";

interface LocationsTableProps {
  loading: boolean;
  error: ApolloError | undefined;
  locations: Location[] | undefined;
}

const LocationsTable: React.FC<LocationsTableProps> = ({
  loading,
  error,
  locations,
}) => {
  const customersMap = !!locations
    ? [...locations]
        .sort((a, b) => {
          // No customer => item should be in the back
          if (!a.customer) return 1;
          if (!b.customer) return -1;
          // Customer present, sort alphabetically
          return a.customer.name.localeCompare(b.customer.name);
        })
        .reduce(
          (entryMap, location) =>
            entryMap.set(location.customer ?? null, [
              ...(entryMap.get(location.customer ?? null) || []),
              location,
            ]),
          new Map<Customer | null, Location[]>()
        )
    : null;

  const { t } = useTranslation();

  return (
    <Table>
      <thead className="bg-gray-50">
        <tr>
          <Th>Name</Th>
          <Th>Address</Th>
          <Th last={true}>
            <span className="sr-only">{t("view")}</span>
          </Th>
        </tr>
      </thead>

      {/* Render content or info/error state */}
      <tbody>
        {loading ? (
          // Loading state
          <TrLoading />
        ) : error ? (
          // Error state
          <TrError>
            <p>{error.toString()}</p>
          </TrError>
        ) : !!customersMap?.size ? (
          // Table
          Array.from(customersMap).map(([customer, locations]) => (
            <React.Fragment key={customer?.id ?? "noCustomer"}>
              {/* Customer row */}
              <tr className="bg-gray-50 border-t border-b border-gray-200">
                <th
                  colSpan={3}
                  className="px-6 py-4 whitespace-nowrap text-sm text-left font-medium text-gray-900"
                >
                  {customer ? (
                    <Link
                      to={`/customers/detail?id=${customer.id}`}
                      className="text-andritz-500 hover:text-andritz-600"
                    >
                      {customer?.name}
                    </Link>
                  ) : (
                    t("no-customer")
                  )}
                </th>
              </tr>

              {/* Locations */}
              {locations.map((location, idx) => (
                <Tr key={location.id} bgGray={idx % 2 === 1}>
                  <Td>
                    <span className="text-gray-400 mr-2">└</span>

                    <Link
                      to={`/locations/detail?id=${location.id}`}
                      className="text-andritz-500 hover:text-andritz-600"
                    >
                      {location.name}
                    </Link>
                  </Td>
                  <Td>{location.address}</Td>
                  <Td last={true}>
                    <Link
                      to={`/locations/detail?id=${location.id}`}
                      className="font-medium text-andritz-500 hover:text-andritz-600"
                    >
                      {t("view")}
                    </Link>
                  </Td>
                </Tr>
              ))}
            </React.Fragment>
          ))
        ) : (
          // Zero state
          <TrZero>{t("locations-zero")}</TrZero>
        )}
      </tbody>
    </Table>
  );
};

export default LocationsTable;
