import { useState } from "react";
import { CompareFn, SortOption } from "../models/ui-sort";

export const useSort = <T>(
  compareFns: {
    [key: string]: CompareFn<T>;
  },
  sortOptions: SortOption[]
) => {
  const [sortBy, setSortBy] = useState<string>();

  const compareFn = compareFns[sortBy ?? ""] ?? (() => 0);

  const extendedSortOptions: SortOption[] = sortOptions.map((option) => ({
    ...option,
    current: option.key === sortBy,
  }));

  return {
    sortBy,
    setSortBy,
    compareFn,
    sortOptions: extendedSortOptions,
  };
};
