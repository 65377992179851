import { Tab } from "@headlessui/react";
import {
  ChartBarIcon,
  ClipboardListIcon,
  GlobeAltIcon,
  UserIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Alert from "../../components/alert";
import CardTooltip from "../../components/card-tooltip";
import CircularProgress from "../../components/circular-progress";
import ClearFilterPressData from "../../components/clear-filter-press-data";
import FilterPlateHeatmap from "../../components/filter-plate-heatmap";
import FilterPlateStatus from "../../components/filter-plate-status";
import FilterPlatesTable from "../../components/filter-plates-table";
import FilterPressTransactions from "../../components/filter-press-transactions";
import Header from "../../components/header";
import FilterPlateIconSolid from "../../components/icons/FilterPlateIconSolid";
import Link from "../../components/link";
import OperationTimesStat from "../../components/stats/operation-times";
import RecentInstallsStat from "../../components/stats/recent-installs";
import UpcomingReplacementsStat from "../../components/stats/upcoming-replacements";
import StatusBadge from "../../components/status-badge";
import { useGetFilterPress } from "../../operations/queries/filter-presses";
import Custom404 from "../404";

const FilterPressPage: React.FC = () => {
  const { t } = useTranslation();

  // Get filter press id from URL params
  const [searchParams] = useSearchParams();
  const filterPressId = searchParams.get("id") ?? undefined;

  // Fetch filter press data
  const { loading, error, filterPress } = useGetFilterPress(filterPressId);

  if (!loading && !filterPress && !error) {
    return <Custom404 />;
  }

  // Info & specification content
  const infoContent = [
    {
      head: t("status"),
      content: !!filterPress ? (
        <StatusBadge status={filterPress.isActive} type="active" />
      ) : undefined,
    },
    {
      head: t("model-number"),
      content: <span className="font-mono">{filterPress?.modelNumber}</span>,
    },
    {
      head: t("serial-number"),
      content: <span className="font-mono">{filterPress?.serialNumber}</span>,
    },
    {
      head: t("number-of-plates"),
      content: filterPress?.numberOfPlates,
    },
    {
      head: t("documentation"),
      content: !!filterPress?.documentation?.url ? (
        <a
          href={filterPress.documentation.url}
          target="_blank"
          rel="noreferrer"
          className="font-medium text-andritz-500 hover:text-andritz-600"
        >
          {t("view-documentation")}
        </a>
      ) : undefined,
    },
    {
      head: t("actions"),
      content: <ClearFilterPressData press={filterPress} />,
    },
  ].filter(({ content }) => !!content);

  const tabs = [
    {
      name: t("dashboard"),
      icon: ChartBarIcon,
      content: !filterPress ? undefined : (
        <div className="space-y-6">
          {/* Top grid */}
          <div className="grid xl:grid-cols-2 3xl:grid-cols-5 gap-6 items-start">
            {/* KPIs */}
            <div className="3xl:col-span-3 grid gap-6 items-start">
              <div className="grid 3xl:grid-cols-2 gap-6 items-start sm:max-w-md xl:max-w-none xl:order-1">
                <RecentInstallsStat press={filterPress} />
                <UpcomingReplacementsStat press={filterPress} />
              </div>

              <OperationTimesStat press={filterPress} />
            </div>

            {/* Latest transactions */}
            <div className="3xl:col-span-2 shadow rounded-md bg-white overflow-hidden">
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {t("latest-transactions")}
                </h3>
              </div>
              <div className="px-4 py-5 sm:px-6">
                <FilterPressTransactions pressId={filterPress.id} limit={3} />
              </div>
            </div>
          </div>

          {/* Status diagram */}
          <div className="shadow rounded-md bg-white overflow-hidden">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
              <div className="flex space-x-3">
                <div className="flex-shrink-0 flex-1">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {t("filter-plate-status")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {t("filter-plate-status-description")}
                  </p>
                </div>

                <div className="flex-shrink-0 self-center flex">
                  <CardTooltip>
                    <p className="text-sm text-gray-500">
                      {t("filter-plate-status-info")}
                    </p>
                  </CardTooltip>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <FilterPlateStatus press={filterPress} />
            </div>
          </div>

          {/* Plate heatmap */}
          <div className="shadow rounded-md bg-white overflow-hidden">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {t("filter-plate-heatmap")}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {t("filter-plate-heatmap-description")}
              </p>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <FilterPlateHeatmap press={filterPress} />
            </div>
          </div>
        </div>
      ),
    },
    {
      name: t("filter-plate", { count: 2 }),
      icon: FilterPlateIconSolid,
      content: (
        <FilterPlatesTable
          loading={loading}
          error={error}
          filterPlates={filterPress?.plates}
        />
      ),
    },
    {
      name: t("transaction", { count: 2 }),
      icon: ClipboardListIcon,
      content: (
        <div className="shadow rounded-md bg-white px-4 py-5 sm:px-6">
          {!!filterPressId && (
            <FilterPressTransactions pressId={filterPressId} />
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        {!!filterPress?.title && (
          <title>{filterPress.title} | Smart Filtercloth</title>
        )}
      </Helmet>

      <Header
        title={loading ? <CircularProgress /> : filterPress?.title}
        meta={
          loading || !filterPress ? undefined : (
            <>
              {/* Location link */}
              {!filterPress.location ? undefined : (
                <Link
                  to={`/locations/detail?id=${filterPress.location.id}`}
                  className={
                    "mt-2 flex items-center text-sm text-gray-500 hover:text-gray-900"
                  }
                >
                  <GlobeAltIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {filterPress.location.name}
                </Link>
              )}

              {/* Customer link */}
              {!filterPress.location?.customer ? undefined : (
                <Link
                  to={`/customers/detail?id=${filterPress.location.customer.id}`}
                  className={
                    "mt-2 flex items-center text-sm text-gray-500 hover:text-gray-900"
                  }
                >
                  <UserIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {filterPress.location?.customer.name}
                </Link>
              )}
            </>
          )
        }
        breadcrumb={
          !!filterPress
            ? [
                {
                  title: t("filter-press", { count: 2 }),
                  href: "/filter-presses",
                },
                {
                  title: filterPress?.title,
                  href: `/filter-presses/detail?id=${filterPress.id}`,
                },
              ]
            : []
        }
      />

      <div className="px-4 sm:px-6 md:px-8 py-6">
        <div className="space-y-10 max-w-screen-2xl">
          {/* Error alert */}
          {!!error && (
            <div className="shadow rounded-md bg-white overflow-hidden px-4 py-5 sm:px-6">
              <Alert severity="error">
                <p>{error.message}</p>
              </Alert>
            </div>
          )}

          {/* Info & specifications */}
          {!!filterPress && (
            <>
              <div className="shadow rounded-md bg-white overflow-hidden">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {t("info-specifications")}
                  </h3>
                </div>

                <div className="border-t border-gray-200">
                  <dl>
                    {infoContent.map(({ head, content }, index) => (
                      <div
                        key={head}
                        className={classNames(
                          "px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
                          index % 2 === 0 ? "bg-gray-50" : "bg-white"
                        )}
                      >
                        <dt className="text-sm font-medium text-gray-500">
                          {head}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {content}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>

              {/* Content tabs */}
              <div className="space-y-6">
                <Tab.Group manual>
                  <Tab.List>
                    <div className="relative z-0 rounded-md overflow-hidden shadow flex divide-x divide-gray-200 bg-white">
                      {tabs.map(({ name, icon: Icon }) => (
                        <Tab
                          key={name}
                          className={({ selected }) =>
                            classNames(
                              "group relative min-w-0 bg-white py-4 px-4 lg:px-8 text-sm leading-none font-medium text-center hover:bg-gray-50 focus:z-10 whitespace-nowrap",
                              selected
                                ? "text-gray-900"
                                : "text-gray-500 hover:text-gray-700"
                            )
                          }
                        >
                          {({ selected }) => (
                            <>
                              <span className="inline-flex items-center">
                                <Icon
                                  className={classNames(
                                    selected
                                      ? "text-andritz-500"
                                      : "text-gray-400 group-hover:text-gray-500",
                                    "-ml-0.5 mr-2 h-5 w-5 hidden sm:block"
                                  )}
                                  aria-hidden="true"
                                />
                                <span>{name}</span>
                              </span>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  selected
                                    ? "bg-andritz-500"
                                    : "bg-transparent",
                                  "absolute inset-x-0 bottom-0 h-0.5"
                                )}
                              />
                            </>
                          )}
                        </Tab>
                      ))}
                    </div>
                  </Tab.List>

                  <Tab.Panels>
                    {tabs.map(({ name, content }) => (
                      <Tab.Panel key={name}>{content}</Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FilterPressPage;
