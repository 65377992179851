import classNames from "classnames";
import React from "react";

interface TdProps {
  last?: boolean;
}

const Td: React.FC<TdProps> = ({ children, last = false }) => {
  return (
    <td
      className={classNames(
        "px-6 py-4 whitespace-nowrap text-sm text-gray-900",
        {
          "text-right": last,
        }
      )}
    >
      {children}
    </td>
  );
};

export default Td;
