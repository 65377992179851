import classNames from "classnames";
import React from "react";

interface ThProps {
  last?: boolean;
}

const Th: React.FC<ThProps> = ({ children, last = false }) => {
  return (
    <th
      scope="col"
      className={classNames(
        "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
        {
          "text-right": last,
        }
      )}
    >
      {children}
    </th>
  );
};

export default Th;
