import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  connectToDevTools: true,
  uri: process.env.REACT_APP_BACKEND_URL,
  credentials: "include",
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
});

export default client;
