import { gql } from "@apollo/client";
import { FilterPlate } from "./filter-plate";
import { Location } from "./location";

export interface FilterPress {
  id: string;
  title: string;
  isActive: boolean;
  modelNumber: string;
  serialNumber: string;
  maxPlateOperationTime: number;
  numberOfPlates: number;
  clothModeConfig:
    | {
        plateIndex: number;
        clothMode: "dual" | "single";
      }[]
    | null;
  documentation?: {
    url: string;
  };
  averageOperationTimePerPlate?: {
    plateIndex: number;
    time: string;
  }[];
  operationTimes?: {
    month: {
      min: string;
      max: string;
      average: string;
    };
    threeMonths: {
      min: string;
      max: string;
      average: string;
    };
  };
  upcomingReplacements?: {
    week: number;
    month: number;
  };
  recentInstalls?: {
    week: number;
    month: number;
  };
  location?: Location;
  plates?: FilterPlate[];
  sectors?: {
    index: number;
    plates: number[];
    percentage: number;
  }[];
}

export const fragments = {
  filterPress: gql`
    fragment FilterPress on FilterPress {
      id
      title
      isActive
      modelNumber
      serialNumber
      maxPlateOperationTime
      numberOfPlates
      clothModeConfig
    }
  `,
};
