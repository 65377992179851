import React from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "../circular-progress";

interface TrLoadingProps {
  colSpan?: number;
}

const TrLoading: React.FC<TrLoadingProps> = ({ colSpan = 99 }) => {
  const { t } = useTranslation();

  return (
    <tr className="bg-white">
      <td
        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
        colSpan={colSpan}
      >
        <div className="flex items-center gap-3">
          <CircularProgress />
          {t("loading")}
        </div>
      </td>
    </tr>
  );
};

export default TrLoading;
