const FilterPressIcon: React.FC<React.ComponentProps<"svg">> = ({
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 6L22 6"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 6.00001L2 16C2 17.1 2.9 18 4 18C5.10457 18 6 17.1046 6 16L6 15M6 7.00001L6 15M10 7L10 15M10 15L12 15C13.1046 15 14 14.1046 14 13L14 7.00001M10 15L6 15"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 6.00001L22 16C22 17.1 21.1 18 20 18C18.8954 18 18 17.1046 18 16L18 14L18 6.00001"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterPressIcon;
