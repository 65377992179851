import { gql, useQuery } from "@apollo/client";
import { User } from "../../models/user";

export const CURRENT_USER_QUERY = gql`
  query CURRENT_USER_QUERY {
    authenticatedItem {
      ... on User {
        id
        email
        name
        isAdmin
      }
    }
  }
`;

export const useUser = () => {
  const { loading, error, data } = useQuery<{
    authenticatedItem: User;
  }>(CURRENT_USER_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const user = data?.authenticatedItem;

  return {
    loading,
    error,
    user,
  };
};

export const isAdmin = ({ isAdmin }: User) => isAdmin;
