import { GlobeAltIcon, UserIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { format, formatDistance, formatDistanceToNow } from "date-fns";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Alert from "../../components/alert";
import FilterClothTransactions from "../../components/filter-cloth-transactions";
import Header from "../../components/header";
import Heatmap from "../../components/heatmap";
import FilterPlateIconSolid from "../../components/icons/FilterPlateIconSolid";
import FilterPressIconSolid from "../../components/icons/FilterPressIconSolid";
import Link from "../../components/link";
import StatusBadge from "../../components/status-badge";
import { getDateFnsLocale, smartTrim } from "../../lib/helpers";
import { useGetFilterCloth } from "../../operations/queries/filter-cloths";
import Custom404 from "../404";

const FilterClothPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const locale = getDateFnsLocale(i18n.resolvedLanguage);

  // Get filter cloth id from URL params
  const [searchParams] = useSearchParams();
  const filterClothId = searchParams.get("id") ?? undefined;

  // Fetch filter cloth data
  const { loading, error, filterCloth } = useGetFilterCloth(filterClothId);

  if (!loading && !filterCloth && !error) {
    return <Custom404 />;
  }

  // Date calculations
  const installedTime = !!filterCloth?.installedTime
    ? new Date(filterCloth?.installedTime)
    : undefined;
  const uninstalledTime = !!filterCloth?.uninstalledTime
    ? new Date(filterCloth?.uninstalledTime)
    : undefined;

  let operationTime = undefined;
  if (!!installedTime) {
    if (!uninstalledTime) {
      // Installed -> now
      operationTime = formatDistanceToNow(installedTime, {
        locale,
      });
    } else if (!!uninstalledTime) {
      if (uninstalledTime.getTime() > installedTime.getTime()) {
        // Installed -> uninstalled
        operationTime = formatDistance(installedTime, uninstalledTime, {
          locale,
        });
      } else {
        // Installed -> now
        operationTime = formatDistanceToNow(installedTime, {
          locale,
        });
      }
    }
  }

  // Info & specification content
  const infoContent = [
    {
      head: t("id"),
      content: <span className="font-mono">{filterCloth?.fcid}</span>,
    },
    {
      head: t("status"),
      content: filterCloth ? (
        <StatusBadge status={filterCloth.installed} type="installed" />
      ) : undefined,
    },
    {
      head: t("installed-at"),
      content: installedTime
        ? format(installedTime, "PPPpp", { locale })
        : undefined,
    },
    {
      head: t("uninstalled-at"),
      content: uninstalledTime
        ? format(uninstalledTime, "PPPpp", { locale })
        : undefined,
    },
    {
      head: t("operation-time"),
      content: operationTime,
    },
  ];

  // Truncates the long FCID to be displayed in the header
  const headerFcid = !!filterCloth ? smartTrim(filterCloth?.fcid) : "";

  return (
    <>
      <Helmet>
        <title>{t("filter-cloth")} | Smart Filtercloth</title>
      </Helmet>

      <Header
        title={
          <>
            {t("filter-cloth")}{" "}
            <span className="font-mono text-gray-500 text-lg font-normal">
              {headerFcid}
            </span>
          </>
        }
        meta={
          loading || !filterCloth ? undefined : (
            <>
              {/* Filter plate link */}
              {!filterCloth.plate ? undefined : (
                <Link
                  to={`/filter-plates/detail?id=${filterCloth.plate.id}`}
                  className={
                    "mt-2 flex items-center text-sm text-gray-500 hover:text-gray-900"
                  }
                >
                  <FilterPlateIconSolid
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {filterCloth.plate.title}
                </Link>
              )}

              {/* Filter press link */}
              {!filterCloth.plate?.press ? undefined : (
                <Link
                  to={`/filter-presses/detail?id=${filterCloth.plate.press.id}`}
                  className={
                    "mt-2 flex items-center text-sm text-gray-500 hover:text-gray-900"
                  }
                >
                  <FilterPressIconSolid
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {filterCloth.plate.press.title}
                </Link>
              )}

              {/* Location link */}
              {!filterCloth.plate?.press.location ? undefined : (
                <Link
                  to={`/locations/detail?id=${filterCloth.plate?.press.location.id}`}
                  className={
                    "mt-2 flex items-center text-sm text-gray-500 hover:text-gray-900"
                  }
                >
                  <GlobeAltIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {filterCloth.plate?.press.location.name}
                </Link>
              )}

              {/* Customer link */}
              {!filterCloth.plate?.press.location?.customer ? undefined : (
                <Link
                  to={`/customers/detail?id=${filterCloth.plate?.press.location.customer.id}`}
                  className={
                    "mt-2 flex items-center text-sm text-gray-500 hover:text-gray-900"
                  }
                >
                  <UserIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {filterCloth.plate?.press.location?.customer.name}
                </Link>
              )}
            </>
          )
        }
        breadcrumb={
          !!filterCloth
            ? [
                {
                  title: t("filter-cloth", { count: 2 }),
                  href: "/filter-cloths",
                },
                {
                  title: t("filter-cloth"),
                  href: `/filter-cloths/detail?id=${filterCloth.id}`,
                },
              ]
            : []
        }
      />

      {/* Content */}
      <div className="px-4 sm:px-6 md:px-8 py-6">
        {/* Error alert */}
        {!!error && (
          <div className="max-w-screen-2xl">
            <div className="shadow rounded-md bg-white overflow-hidden px-4 py-5 sm:px-6">
              <Alert severity="error">
                <p>{error.message}</p>
              </Alert>
            </div>
          </div>
        )}

        {/* Content */}
        {!!filterCloth && (
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-6 max-w-screen-2xl">
            {/* Info & specifications */}
            <div className="shadow rounded-md bg-white overflow-hidden xl:col-span-2">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {t("info-specifications")}
                </h3>
              </div>

              <div className="border-t border-gray-200">
                <dl>
                  {infoContent.map(
                    ({ head, content }, index) =>
                      !!content && (
                        <div
                          key={head}
                          className={classNames(
                            "px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
                            index % 2 === 0 ? "bg-gray-50" : "bg-white"
                          )}
                        >
                          <dt className="text-sm font-medium text-gray-500">
                            {head}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {content}
                          </dd>
                        </div>
                      )
                  )}
                </dl>
              </div>
            </div>

            {/* Highlighted areas */}
            <div className="shadow rounded-md bg-white overflow-hidden">
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {t("highlighted-areas")}
                </h3>
              </div>
              <div className="px-4 py-5 sm:px-6">
                <div className="max-w-3xl">
                  <Heatmap highlightedAreas={filterCloth.highlightedAreas} />
                </div>
              </div>
            </div>

            {/* Transactions */}
            <div className="shadow rounded-md bg-white overflow-hidden">
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {t("transaction", { count: 2 })}
                </h3>
              </div>
              <div className="px-4 py-5 sm:px-6">
                <FilterClothTransactions cloth={filterCloth} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FilterClothPage;
