import React from "react";

interface TrErrorProps {
  colSpan?: number;
}

const TrZero: React.FC<TrErrorProps> = ({ colSpan = 99, children }) => {
  return (
    <tr className="bg-white">
      <td
        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
        colSpan={colSpan}
      >
        {children}
      </td>
    </tr>
  );
};

export default TrZero;
