import { compact } from "lodash";
import React, { useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import FilterPressesTable from "../components/filter-presses-table";
import Header from "../components/header";
import TableActions from "../components/table-actions";
import TableSearch from "../components/table-search";
import { useSearch } from "../lib/useSearch";
import { useUiFilters } from "../lib/useUiFilters";
import { FilterPress } from "../models/filter-press";
import { useGetAllFilterPresses } from "../operations/queries/filter-presses";
import { useGetAllLocations } from "../operations/queries/locations";

const FilterPressesPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  // Get filter presses from API
  const { loading, error, filterPresses } = useGetAllFilterPresses();

  // Sort filter presses alphabetically
  const sortedFilterPresses = !!filterPresses?.length
    ? [...filterPresses].sort((a, b) =>
        a.title.localeCompare(b.title, i18n.resolvedLanguage, { numeric: true })
      )
    : filterPresses;

  // Filter presses for filtering
  const { locations } = useGetAllLocations();

  // Set up UI filters
  const {
    filteredObjects: filteredFilterPresses,
    filterState,
    filterGroups,
    filterChangeHandler,
  } = useUiFilters<FilterPress>(
    sortedFilterPresses,
    useCallback(
      () =>
        locations?.length
          ? [
              {
                id: "status",
                name: t("status"),
                filters: [
                  { value: "active", label: t("active") },
                  { value: "not-active", label: t("not-active") },
                ],
              },
              {
                id: "location",
                name: t("location"),
                filters: locations.map((location) => ({
                  itemGroup: location.customer?.name,
                  value: location.id,
                  label: location.name,
                })),
              },
            ]
          : [],
      [locations, t]
    ),
    (filterPress, filterStateItemKey) => ({
      status:
        (filterStateItemKey === "active" && filterPress.isActive) ||
        (filterStateItemKey === "not-active" && !filterPress.isActive),
      location: filterPress?.location?.id === filterStateItemKey,
    })
  );

  // Search
  const {
    searchedObjects: searchedFilterPresses,
    searchTerm,
    searchChangeHandler,
  } = useSearch<FilterPress>(filteredFilterPresses, (filterPress) =>
    compact([filterPress.title, filterPress.location?.name])
  );

  return (
    <>
      <Helmet>
        <title>{t("filter-press", { count: 2 })} | Smart Filtercloth</title>
      </Helmet>

      <Header title={t("filter-press", { count: 2 })} />

      <div className="px-4 sm:px-6 md:px-8 py-6">
        <div className="max-w-screen-2xl">
          {/* Actions & list */}
          <div className="shadow rounded-md bg-white px-4 py-5 sm:px-6 space-y-4">
            <TableSearch
              searchChangeHandler={searchChangeHandler}
              searchTerm={searchTerm}
            />
            <TableActions
              filterChangeHandler={filterChangeHandler}
              filterGroups={filterGroups}
              filterState={filterState}
            />
            <FilterPressesTable
              loading={loading}
              error={error}
              filterPresses={searchedFilterPresses}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterPressesPage;
