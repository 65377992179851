import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { kebabCase } from "lodash";
import React, { ChangeEvent, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { groupFiltersByItemGroup } from "../lib/useUiFilters";
import { UiFilterGroup, UiFilterState } from "../models/ui-filters";
import { SortState } from "../models/ui-sort";

interface TableActionsProps {
  filterGroups: UiFilterGroup[];
  filterState?: UiFilterState;
  filterChangeHandler: (
    e: ChangeEvent<HTMLInputElement>,
    filterGroupKey: string
  ) => void;
  sortState?: SortState;
}

const TableActions: React.FC<TableActionsProps> = ({
  filterChangeHandler,
  filterState,
  filterGroups,
  sortState,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (!filterState) {
    return null;
  }

  // Extend filter groups with count and item grouping
  const extendedFilterGroups = filterGroups.map((filterGroup) => ({
    ...filterGroup,
    groupedFilters: groupFiltersByItemGroup(filterGroup.filters),
    count: Object.values(filterState[filterGroup.id]).reduce(
      (sum, curr) => sum + (curr ? 1 : 0),
      0
    ),
  }));

  const { setSortBy, sortBy, sortOptions } = sortState ?? {};

  return (
    <div className="space-y-5">
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 sm:hidden"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto">
              <div className="px-4 flex items-center justify-between">
                <h2 className="text-lg font-medium text-gray-900">
                  {t("filters")}
                </h2>
                <button
                  type="button"
                  className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-andritz-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">{t("close-filters")}</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4">
                {extendedFilterGroups.map((filterGroup) => (
                  <Disclosure
                    as="div"
                    key={filterGroup.id}
                    className="border-t border-gray-200 px-4 py-6"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-mx-2 -my-3 flow-root">
                          <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400">
                            <div>
                              {/* Group label */}
                              <span className="font-medium text-gray-900">
                                {filterGroup.name}
                              </span>

                              {/* Filter count indicator */}
                              {filterGroup.count > 0 && (
                                <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                                  {filterGroup.count}
                                </span>
                              )}
                            </div>

                            {/* Indicator icon */}
                            <span className="ml-6 flex items-center">
                              <ChevronDownIcon
                                className={classNames(
                                  open ? "-rotate-180" : "rotate-0",
                                  "h-5 w-5"
                                )}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-6">
                            {Object.entries(filterGroup.groupedFilters)
                              // Sort item groups alphabetically
                              .sort(([a], [b]) => a.localeCompare(b))
                              .map(([itemGroup, filters]) => (
                                <React.Fragment key={itemGroup}>
                                  {/* Item group */}
                                  {itemGroup !== "_no-group" && (
                                    <div className="text-xs font-medium text-gray-500 uppercase tracking-wider !-mb-1">
                                      {itemGroup}
                                    </div>
                                  )}

                                  {/* Filter checkboxes */}
                                  {filters.map((filter, filterIdx) => {
                                    const filterId = kebabCase(
                                      `filter-mobile-${filterGroup.id}-${itemGroup}-${filterIdx}`
                                    );

                                    return (
                                      <div
                                        key={filter.value}
                                        className="flex items-center"
                                      >
                                        <input
                                          id={filterId}
                                          name={`${filterGroup.id}[]`}
                                          value={filter.value}
                                          checked={
                                            filterState[filterGroup.id][
                                              filter.value
                                            ]
                                          }
                                          type="checkbox"
                                          className="h-4 w-4 border-gray-300 rounded text-andritz-500 focus:ring-andritz-500"
                                          onChange={(e) =>
                                            filterChangeHandler(
                                              e,
                                              filterGroup.id
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor={filterId}
                                          className="ml-3 text-sm font-medium text-gray-900"
                                        >
                                          {filter.label}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </React.Fragment>
                              ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* Filter bar */}
      <div className="flex items-center justify-between">
        {!!sortOptions?.length && (
          <Menu as="div" className="relative z-10 inline-block text-left">
            <div>
              <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                {t("sort")}
                <ChevronDownIcon
                  className="shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-left absolute left-0 z-10 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {sortOptions.map((option) => (
                    <Menu.Item key={option.key}>
                      {({ active }) => (
                        <button
                          className={classNames(
                            option.current
                              ? "font-medium text-gray-900"
                              : "text-gray-500",
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm w-full text-left"
                          )}
                          onClick={
                            !!setSortBy
                              ? () => {
                                  // Toggle sort
                                  setSortBy(
                                    option.key === sortBy
                                      ? undefined
                                      : option.key
                                  );
                                }
                              : undefined
                          }
                        >
                          {option.label}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}

        <button
          type="button"
          className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden ml-auto"
          onClick={() => setOpen(true)}
        >
          {t("filters")}
        </button>

        <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8 ml-auto">
          {extendedFilterGroups.map((filterGroup) => (
            <Popover
              as="div"
              key={filterGroup.id}
              id="desktop-menu"
              className="relative z-10 inline-block text-left"
            >
              <div>
                <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  <span>{filterGroup.name}</span>

                  {/* Filter count indicator */}
                  {filterGroup.count > 0 && (
                    <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                      {filterGroup.count}
                    </span>
                  )}
                  <ChevronDownIcon
                    className="shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Popover.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <form className="space-y-4">
                    {Object.entries(filterGroup.groupedFilters)
                      // Sort item groups alphabetically
                      .sort(([a], [b]) => a.localeCompare(b))
                      .map(([itemGroup, filters]) => (
                        <React.Fragment key={itemGroup}>
                          {/* Item group */}
                          {itemGroup !== "_no-group" && (
                            <div className="text-xs font-medium text-gray-500 uppercase tracking-wider !-mb-1">
                              {itemGroup}
                            </div>
                          )}

                          {/* Filter checkboxes */}
                          {filters.map((filter, filterIdx) => {
                            const filterId = kebabCase(
                              `filter-${filterGroup.id}-${itemGroup}-${filterIdx}`
                            );
                            return (
                              <div
                                className="flex items-center"
                                key={filter.value}
                              >
                                <input
                                  id={filterId}
                                  name={`${filterGroup.id}[]`}
                                  value={filter.value}
                                  checked={
                                    filterState[filterGroup.id][filter.value]
                                  }
                                  type="checkbox"
                                  className="h-4 w-4 border-gray-300 rounded text-andritz-500 focus:ring-andritz-500"
                                  onChange={(e) =>
                                    filterChangeHandler(e, filterGroup.id)
                                  }
                                />
                                <label
                                  htmlFor={filterId}
                                  className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                                >
                                  {filter.label}
                                </label>
                              </div>
                            );
                          })}
                        </React.Fragment>
                      ))}
                  </form>
                </Popover.Panel>
              </Transition>
            </Popover>
          ))}
        </Popover.Group>
      </div>
    </div>
  );
};

export default TableActions;
