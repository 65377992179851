import { Listbox, Transition } from "@headlessui/react";
import { ArrowUpIcon } from "@heroicons/react/outline";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CogIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import { addMonths, differenceInDays } from "date-fns";
import { round } from "lodash";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterPress } from "../../models/filter-press";
import CardTooltip from "../card-tooltip";

interface RecentInstallsStatProps {
  press: FilterPress;
}

const RecentInstallsStat: React.FC<RecentInstallsStatProps> = ({ press }) => {
  const { t } = useTranslation();
  const now = Date.now();

  const timeframeOptions = [
    { name: t("week"), value: "week", days: 7 },
    {
      name: t("month"),
      value: "month",
      days: differenceInDays(addMonths(now, 1), now),
    },
  ];

  const [timeframe, setTimeframe] = useState(timeframeOptions[1]);

  const recentInstalls =
    press.recentInstalls?.[timeframe.value as "week" | "month"];

  if (typeof recentInstalls === "undefined") {
    return null;
  }

  // Deviation
  const suggestedInstalls =
    press.numberOfPlates * 1.5 * (timeframe.days / press.maxPlateOperationTime);
  const deviation = `${Math.abs(
    round((recentInstalls / suggestedInstalls) * 100 - 100, 2)
  )}%`;
  const deviationType = recentInstalls > suggestedInstalls ? "above" : "below";

  return (
    <dl className="relative bg-white py-5 px-4 sm:p-6 shadow rounded-lg flex gap-x-3 justify-between items-start">
      <div>
        {/* Icon & title */}
        <dt>
          <div className="absolute bg-andritz-500 rounded-md p-3">
            <ArrowUpIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <p className="ml-16 text-sm font-medium text-gray-500 truncate">
            {t("stat-recent-installs-title")}
          </p>
        </dt>

        {/* Content */}
        <dd className="ml-16 flex flex-wrap items-baseline gap-x-2 gap-y-0.5">
          {/* Stat */}
          <p className="text-sm text-gray-900 font-semibold">
            <span className="text-2xl leading-tight">{recentInstalls}</span>{" "}
            {t("stat-recent-installs-unit", { count: recentInstalls })}
          </p>

          {/* Deviation */}
          <p
            className={classNames(
              deviationType === "above" ? "text-red-600" : "text-green-600",
              "flex items-baseline text-sm font-semibold"
            )}
          >
            {deviationType === "above" ? (
              <ChevronUpIcon
                className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                aria-hidden="true"
              />
            )}

            <span className="sr-only">
              {t("deviation-by")} {deviationType === "above" ? "+" : "-"}
            </span>

            {deviation}
          </p>
        </dd>
      </div>

      {/* Settings & Info */}
      <div className="flex gap-2 items-center -mt-2.5 sm:-mt-3 sm:-mr-3">
        <Listbox
          as="div"
          value={timeframe}
          onChange={setTimeframe}
          className="flex-shrink-0"
        >
          {({ open }) => (
            <>
              <Listbox.Label className="sr-only">
                {t("select-timeframe")}
              </Listbox.Label>
              <div className="relative">
                <Listbox.Button className="relative inline-flex items-center rounded-full p-2 bg-gray-100 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-200 sm:py-1.5 sm:px-3">
                  <CogIcon
                    className="flex-shrink-0 h-5 w-5 sm:-ml-1"
                    aria-hidden="true"
                  />

                  <span className="hidden truncate sm:ml-2 sm:block text-gray-900">
                    {timeframe.name}
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-75"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Listbox.Options className="origin-top-right absolute right-0 top-full z-10 mt-1 w-48 bg-white shadow-lg rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {timeframeOptions.map((timeframeOption) => (
                      <Listbox.Option
                        key={timeframeOption.value}
                        className={({ active }) =>
                          classNames(
                            active ? "bg-gray-100" : "bg-white",
                            "cursor-pointer select-none relative py-2 px-3"
                          )
                        }
                        value={timeframeOption}
                      >
                        <div className="flex items-center">
                          <span className="block font-medium truncate">
                            {timeframeOption.name}
                          </span>
                        </div>
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>

        <CardTooltip>
          <p className="text-sm text-gray-500">
            {t("stat-recent-installs-info")}
          </p>
        </CardTooltip>
      </div>
    </dl>
  );
};

export default RecentInstallsStat;
