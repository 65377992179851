import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Color,
  defaults,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScriptableContext,
  Tooltip,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { formatDistanceStrict } from "date-fns";
import Gradient from "javascript-color-gradient";
import React from "react";
import { Chart } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import colors from "tailwindcss/colors";
import { days2Ms, getDateFnsLocale } from "../lib/helpers";
import { FilterPlateSide } from "../models/filter-plate";
import { FilterPress } from "../models/filter-press";
import { useGetFilterPlatesChartData } from "../operations/queries/filter-plates";
import Alert from "./alert";
import CircularProgress from "./circular-progress";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  Legend,
  Tooltip,
  annotationPlugin
);

interface PlateStatusProps {
  press: FilterPress;
}

const FilterPlateStatus: React.FC<PlateStatusProps> = ({ press }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { filterPlates, loading, error } = useGetFilterPlatesChartData(
    press.id
  );

  // Handle error, loading and zero cases
  if (!!error) {
    return (
      <Alert severity="error">
        <p>{error.message}</p>
      </Alert>
    );
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (!filterPlates?.length) {
    return (
      <Alert severity="info">
        <p>{t("filter-plates-zero")}</p>
      </Alert>
    );
  }

  // Set chart styling defaults
  defaults.font.family = window.getComputedStyle(document.body).fontFamily;
  defaults.borderColor = colors.gray[200];
  defaults.backgroundColor = colors.gray[200];
  defaults.color = colors.gray[500];

  // Locale for y axis
  const locale = getDateFnsLocale(i18n.resolvedLanguage);

  // X axis labels = filter plate indexes
  const labels = filterPlates.map(({ plateIndex }) => plateIndex);

  // Set up bar background colors based on operation time
  const maxOperationTime = days2Ms(press.maxPlateOperationTime);
  const redTime = maxOperationTime * 1.5;
  const greenTime = maxOperationTime * 0.5;
  const operationTimeColors = new Gradient();
  operationTimeColors.setMidpoint(redTime - greenTime);
  operationTimeColors.setGradient(
    colors.emerald[400],
    colors.amber[400],
    colors.red[400]
  );

  // Helper fn to generate operation time bar data for a filter plate side
  const barData = (side: FilterPlateSide) =>
    filterPlates.map(({ cloth, clothFront, clothBack, plateIndex }) => {
      // Choose filter cloth for side
      const filterCloth = {
        single: cloth,
        front: clothFront,
        back: clothBack,
      }[side];

      // Get install/uninstall dates
      const installedTime = !!filterCloth?.installedTime
        ? new Date(filterCloth?.installedTime)
        : undefined;
      const uninstalledTime = !!filterCloth?.uninstalledTime
        ? new Date(filterCloth?.uninstalledTime)
        : undefined;
      const now = new Date();

      // Y values: milliseconds between now and installed time, or null
      return {
        x: plateIndex,
        y: !!installedTime
          ? !!uninstalledTime &&
            uninstalledTime.getTime() > installedTime.getTime()
            ? null
            : now.getTime() - installedTime.getTime()
          : null,
      };
    });

  return (
    <Chart
      type="bar"
      data={{
        labels,
        datasets: [
          // Historical average operation time
          {
            type: "line" as const,
            label: t("historical-average"),
            backgroundColor: colors.gray[800],
            borderColor: colors.gray[800],
            borderWidth: 2,
            pointRadius: 0,
            pointHoverRadius: 2,
            tension: 0.5,
            data: filterPlates.map(({ plateIndex }) => ({
              x: plateIndex,
              y: +(
                press.averageOperationTimePerPlate?.find(
                  ({ plateIndex: index }) => index === plateIndex
                )?.time ?? 0
              ),
            })),
          },
          // Operation time datasets for each plate side
          ...(["front", "back", "single"] as const).map((side) => ({
            type: "bar" as const,
            label: t(
              side === "single" ? `filter-cloth` : `filter-cloth-${side}`
            ),
            barPercentage: side === "single" ? 1 : 0.875,
            backgroundColor: (context: ScriptableContext<"bar">) =>
              operationTimeColors.getColor(
                Math.max(Number.EPSILON, (context.parsed?.y || 0) - greenTime)
              ),
            data: barData(side),
            grouped: side !== "single",
          })),
        ],
      }}
      options={{
        // Tooltip shouldn't jump between datasets
        interaction: {
          mode: "index",
        },
        plugins: {
          // Max operation time indicator line
          annotation: {
            annotations: {
              line1: {
                type: "line",
                yMin: maxOperationTime,
                yMax: maxOperationTime,
                borderColor: "#006DB1", // Andritz blue
                borderWidth: 2,
              },
            },
          },
          // Legend configuration
          legend: {
            labels: {
              // Color bar charts legends
              generateLabels: (chart) =>
                defaults.plugins.legend.labels.generateLabels
                  .apply(this, [chart])
                  .map((label) => {
                    if (
                      chart.config.data.datasets[label.datasetIndex]?.type ===
                      "bar"
                    ) {
                      label.fillStyle = operationTimeColors.getColor(
                        Number.EPSILON
                      );
                    }

                    return label;
                  }),
            },
            title: {
              display: true,
              text: t("operation-time"),
            },
          },
          // Tooltip configuration
          tooltip: {
            intersect: false,
            mode: "index",
            position: "average",
            backgroundColor: "#fff",
            boxPadding: 2,
            padding: 8,
            borderColor: colors.gray[300],
            borderWidth: 1,
            titleColor: colors.gray[800],
            bodyColor: defaults.color,
            footerColor: defaults.color,
            titleFont: { weight: "500" },
            callbacks: {
              title: ([tooltipItem]) => {
                const filterPlate = filterPlates.find(
                  (_, index) => index === tooltipItem.dataIndex
                );
                return filterPlate?.title || tooltipItem.label;
              },

              beforeBody: () => t("operation-time"),
              labelColor: (context) => ({
                borderColor: "transparent",
                backgroundColor: context.element.options
                  .backgroundColor as Color,
              }),
              // Tooltip labels shouldn't render if dataset isn't present on current index (aka if a cloth isn't installed on a specific side)
              label: (context) => {
                if (
                  context.parsed.y === null ||
                  typeof context?.parsed?.y === "undefined"
                ) {
                  return "";
                }

                let label = context.dataset.label || "";

                if (label) {
                  label += ": ";
                }

                label += formatDistanceStrict(+context.parsed.y, 0, {
                  unit: "day",
                  roundingMethod: "ceil",
                  locale,
                });

                return label;
              },
            },
          },
        },
        // Axis configuration
        scales: {
          x: {
            title: {
              display: true,
              text: t("filter-plate"),
            },
          },
          y: {
            reverse: true,
            title: {
              display: true,
              text: t("operation-time"),
            },
            ticks: {
              stepSize: days2Ms(5),
              callback: (value) =>
                formatDistanceStrict(+value, 0, {
                  unit: "day",
                  roundingMethod: "ceil",
                  locale,
                }),
            },
          },
        },
        // Pointer cursor on hover
        onHover: (e, activeElements, chart) => {
          chart.canvas.style.cursor = !!activeElements.length
            ? "pointer"
            : "default";
        },
        // Click: Navigate to filter plate
        onClick: (e, activeElements) => {
          const filterPlate = filterPlates.find(
            (_, index) => index === activeElements[0]?.index
          );

          !!filterPlate &&
            navigate(`/filter-plates/detail?id=${filterPlate.id}`);
        },
      }}
    />
  );
};

export default FilterPlateStatus;
