import classNames from "classnames";
import { SortState } from "../../models/ui-sort";

interface ThSortButtonProps {
  sortKey: string;
  label: string;
  sortState?: SortState;
}

const ThSortButton: React.FC<ThSortButtonProps> = ({
  sortKey,
  label,
  sortState,
}) => {
  const { setSortBy, sortBy } = sortState ?? {};

  return (
    <button
      className={classNames(
        sortBy === sortKey ? "font-bold" : "font-medium",
        "uppercase tracking-wider text-gray-500"
      )}
      onClick={
        !!setSortBy
          ? () => {
              // Toggle sort
              setSortBy(sortKey === sortBy ? undefined : sortKey);
            }
          : undefined
      }
    >
      {label}
    </button>
  );
};

export default ThSortButton;
