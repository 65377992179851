import { Navigate } from "react-router-dom";
import { useUser } from "../operations/queries/user";
import CircularProgress from "./circular-progress";

interface SignInGateProps {}

const SignInGate: React.FC<SignInGateProps> = ({ children }) => {
  const { user, loading } = useUser();

  return !!user ? (
    <>{children}</>
  ) : loading ? (
    <div className="p-4">
      <CircularProgress color="text-andritz-500" dimensions="h-6 w-6" />
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default SignInGate;
