import { gql, useQuery } from "@apollo/client";
import {
  FilterCloth,
  fragments,
  ServerFilterCloth,
} from "../../models/filter-cloth";
import { fragments as filterPlateFragments } from "../../models/filter-plate";
import { fragments as filterPressFragments } from "../../models/filter-press";
import { fragments as locationFragments } from "../../models/location";
import { fragments as customerFragments } from "../../models/customer";
import { parseHighlightedAreas } from "../../lib/helpers";
import { useLoadable } from "../../lib/useLoadable";
import { selectedCustomerState } from "../../state";

export const GET_ALL_FILTER_CLOTHS_QUERY = gql`
  query ALL_FILTER_CLOTHS_QUERY($customerId: ID!) {
    filterCloths(
      orderBy: { installedTime: desc }
      where: {
        plate: {
          press: { location: { customer: { id: { equals: $customerId } } } }
        }
      }
    ) {
      ...FilterCloth
      plate {
        ...FilterPlate
        press {
          ...FilterPress
        }
      }
    }
  }
  ${fragments.filterCloth}
  ${filterPlateFragments.filterPlate}
  ${filterPressFragments.filterPress}
`;

export const useGetAllFilterCloths = () => {
  // Get selected customer
  const {
    error: selectedCustomerError,
    loading: selectedCustomerLoading,
    result: selectedCustomer,
  } = useLoadable(selectedCustomerState);

  // Query filter cloths
  const { loading, error, data } = useQuery<{
    filterCloths: ServerFilterCloth[];
  }>(GET_ALL_FILTER_CLOTHS_QUERY, {
    variables: {
      customerId: selectedCustomer?.id,
    },
    skip: !selectedCustomer,
  });

  const filterCloths: FilterCloth[] | undefined = data?.filterCloths
    ? data.filterCloths.map((filterCloth) => ({
        ...filterCloth,
        highlightedAreas: !!filterCloth.highlightedAreas
          ? parseHighlightedAreas(filterCloth.highlightedAreas)
          : undefined,
      }))
    : undefined;

  return {
    loading: loading || selectedCustomerLoading,
    error: error || selectedCustomerError,
    filterCloths,
  };
};

export const GET_FILTER_CLOTH_QUERY = gql`
  query FILTER_CLOTH_QUERY($id: ID!) {
    filterCloth(where: { id: $id }) {
      ...FilterCloth
      plate {
        ...FilterPlate
        press {
          ...FilterPress
          location {
            ...Location
            customer {
              ...Customer
            }
          }
        }
      }
    }
  }
  ${fragments.filterCloth}
  ${filterPlateFragments.filterPlate}
  ${filterPressFragments.filterPress}
  ${locationFragments.location}
  ${customerFragments.customer}
`;

export const useGetFilterCloth = (id?: string) => {
  const { loading, error, data } = useQuery<{
    filterCloth: ServerFilterCloth;
  }>(GET_FILTER_CLOTH_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });

  const filterCloth: FilterCloth | undefined = data?.filterCloth
    ? {
        ...data?.filterCloth,
        highlightedAreas: !!data.filterCloth.highlightedAreas
          ? parseHighlightedAreas(data.filterCloth.highlightedAreas)
          : undefined,
      }
    : undefined;

  return {
    loading,
    error,
    filterCloth,
  };
};
